import React, { useCallback, useMemo, useState } from "react";
import Video from "../Video";
import { getInitials } from "../../utils/initials";
import {
  EllipsisVerticalIcon,
  MicrophoneIcon,
  VideoCameraIcon,
} from "@heroicons/react/24/outline";
import { useAppSelector } from "../../app/hooks";
import { mediaSelector } from "../../features/media/mediaSlice";
import { environmentSelector } from "../../features/environment/environmentSlice";
import streamService from "../../services/streamService";
import { RemotePeerData } from "../../interfaces/componentsInterface/mediaInterface";
import { peerVolumeSelector } from "../../features/peerVolume/peerVolumeSlice";
import { getVolumeClasses } from "../../utils/audio";
import { emojiEventConst, mediaTypeConstant, role } from "../../utils/constant";
import { userDetailsSelector } from "../../features/userDetails/userDetailsSlice";
import { generateThumbnailsUrl } from "../../utils/generateImageURL";
import DefaultImage from '../../assets/images/blur.jpg'
import { chatSelector } from "../../features/chat/chatSlice";

const streamInstance = new streamService().getInstance();

const StudentScreen: React.FC = () => {
  const { remotePeersId, remotePeers, presenter } =
    useAppSelector(mediaSelector);
  const { MULTIPLE_SUPERVISORS } =
    useAppSelector(environmentSelector).environments || {};

  const environments = useAppSelector(environmentSelector);
  const [imageError, setImageError] = useState(false);

  const spacesConfig =
    environments.environments &&
      environments.environments.REACT_APP_SPACES_CONFIG
      ? environments.environments.REACT_APP_SPACES_CONFIG.data[0]
      : "";
  const { custId } = useAppSelector(userDetailsSelector);
  const chatDetails = useAppSelector(chatSelector);
  // This count needed for MultipleSupervisors case for layout
  const nonAgentPeersCount = useMemo(
    () =>
      remotePeersId.filter(
        (id) =>
          remotePeers[id].peerType !== role.AGENT ||
          remotePeers[id].peerUserId === presenter
      ).length,
    [remotePeersId, remotePeers, presenter]
  );
  const peerVolume = useAppSelector(peerVolumeSelector);

  const getFlexWidthAndHeight = useCallback(
    (peersLength: number, itemNo: number) => {
      if (peersLength === 7 && itemNo > 3) return { width: 35, height: 32 };
      if (
        (peersLength === 13 && itemNo > 4) ||
        (peersLength === 14 && itemNo > 8)
      )
        return { width: 28, height: 22 };
      if (peersLength === 1) return { width: 95, height: 100 };
      if (peersLength === 2 || peersLength === 4 || peersLength === 3)
        return { width: 45, height: (peersLength === 4 ||peersLength === 3) ? 47 : 96 };
      if (peersLength >= 5 && peersLength <= 9)
        return {
          width: 33,
          height: peersLength <= 6 ? 47 : 32,
        };
      if (peersLength <= 16 && peersLength > 9)
        return { width: 23, height: peersLength <= 12 ? 30 : 22 };
      return { width: 0, height: 0 };
    },
    []
  );

  
  const renderVideoOrScreen = (
    condition: boolean,
    className: string,
    streamArray: any,
    userId: string
  ) => {
    return condition ? (
      <div
        className={`h-full flex justify-center  ${streamArray.length == 1 ? "bg-white" : ""
          } w-full bg-gray-100 rounded-xl`}
      >
        {streamArray.map((value: any, index: number) =>
          streamArray.length > 1 && index > 0 ? (
            <div className="h-1/4 w-1/4 absolute right-0 bottom-0 flex justify-end items-center gap-1">
              <Video
                className={`w-1/2 border-[3px] border-white bg-white rounded-xl shadow-2xl ${value[1]==="VIDEO" && "object-cover"}`}
                src={value[0]}
                mediaType={value[1]}
                userId={userId}
              />
            </div>
          ) : (
            <Video
              className={`w-1/2 bg-white ${value[1]==="VIDEO" && "object-cover"}`}
              src={value[0]}
              mediaType={value[1]}
              userId={userId}
            />
          )
        )}
      </div>
    ) : null;
  };

  const renderPeerView = (peer: RemotePeerData) => {
    const {
      peerVideoPaused,
      peerScreenStopped,
      peerscreenTwoStopped,
      peerUsername,
      peerName,
      peerUserId,
      imageUrl,
    } = peer;

    if (peerVideoPaused && peerScreenStopped && peerscreenTwoStopped) {
      return (
        <div
          style={{ backgroundImage: `url(${peer.imageUrl ? peer.imageUrl : DefaultImage} )` }}
          className="bg-white bg-cover shadow-xl rounded-xl h-full w-full flex justify-center items-center gap-1 relative"
          key={peerUserId}
        >
          <div className="w-full h-full rounded-xl flex  justify-center items-center gap-1 backdrop-blur-md ">
            {imageUrl && imageError ? (
              <img
                className={`inline-block h-28 w-28 rounded-full ring-2 ring-white ${peerVolume && getVolumeClasses(peerVolume[peerUserId])
                  }`}
                src={imageUrl}
                alt="User"
              />
            ) : imageUrl && !imageError ? (
              <img
                className={`inline-block h-28 w-28 rounded-full ring-2 ring-white  ${peerVolume && getVolumeClasses(peerVolume[peerUserId])
                  }`}
                src={generateThumbnailsUrl(
                  imageUrl.split("/").pop(),
                  480,
                  custId,
                  peerUserId,
                  spacesConfig
                )}
                alt="User"
                onError={() => setImageError(true)}
              />
            ) : (
              <p
                className={` w-10 h-10 p-8  bg-blue rounded-full text-white flex justify-center items-center font-semibold border-4 border-white  ring-2  ring-white shadow-xl ${peerVolume && getVolumeClasses(peerVolume[peerUserId])
                  }`}
              >
                {getInitials(peerName)}
              </p>
            )}
            <p className="lg:text-[.6rem] xl:text-[.9rem] absolute bottom-1 left-1 text-white opacity-70">
              <span className="bg-gray-500 capitalize p-[.5px] px-[2px] backdrop-blur-sm rounded-md">{peerName}</span>
              {chatDetails.onlineUsers.some(user => user.peerUserId === peerUserId && user.emojiForDndPeer === emojiEventConst.DND) && (
                <span className="bg-red-600 px-2 ml-1 p-[.5px] rounded-md">DND</span>
              )}
              {chatDetails.onlineUsers.some(user => user.peerUserId === peerUserId && user.emojiForHandAndLunchPeer === emojiEventConst.LUNCH) && (
                <span className="bg-red-600 px-2 ml-1 p-[.5px] rounded-md">Lunch</span>
              )}
              {chatDetails.onlineUsers.some(user => user.peerUserId === peerUserId && user.emojiForHandAndLunchPeer === emojiEventConst.BRB) && (
                <span className="bg-red-600 px-2 ml-1 p-[.5px] rounded-md">BRB</span>
              )}
              {chatDetails.onlineUsers.some(user => user.peerUserId === peerUserId && user.emojiForHandAndLunchPeer === emojiEventConst.BREAK) && (
                <span className="bg-red-600 px-2 ml-1 p-[.5px] rounded-md">Break</span>
              )}
              {chatDetails.onlineUsers.some(user => user.peerUserId === peerUserId && user.emojiForMeetingPeer === emojiEventConst.MEETING) && (
                <span className="bg-red-600 px-2 ml-1 p-[.5px] rounded-md">Meeting</span>
              )}
            </p>
          </div>
        </div>
      );
    } else {
      // {
      //   console.log(
      //     peerVideoPaused,
      //     peerScreenStopped,
      //     peerscreenTwoStopped,
      //     "checkthrice"
      //   );
      // }
      return (
        <div
          className={`bg-white border-[2px] shadow-xl rounded-xl h-full w-full flex flex-col justify-start items-start gap-1 relative ${peerVolume && peerVolume[peerUserId] !== -100
              ? "border-blue"
              : "border-transparent"
            }`}
          key={peerUserId}
        >
          {streamInstance.peerRemoteStream && (
            <>
              {/* If only screen1 is shared */}
              {renderVideoOrScreen(
                !peerScreenStopped && peerVideoPaused && peerscreenTwoStopped,
                "w-1/2",
                [
                  [
                    streamInstance.peerRemoteStream[peerUserId]?.screenStream,
                    mediaTypeConstant.screen,
                  ],
                ],
                peerUserId
              )}
              {/* If only screen2 is shared */}
              {renderVideoOrScreen(
                !peerscreenTwoStopped && peerVideoPaused && peerScreenStopped,
                "w-1/2",

                [
                  [
                    streamInstance.peerRemoteStream[peerUserId]
                      ?.screenTwoStream,
                    mediaTypeConstant.screentwo,
                  ],
                ],

                peerUserId
              )}
              {/* If only video is shared */}
              {renderVideoOrScreen(
                !peerVideoPaused && peerscreenTwoStopped && peerScreenStopped,
                "w-full ",
                [
                  [
                    streamInstance.peerRemoteStream[peerUserId]?.videoStream,
                    mediaTypeConstant.video,
                  ],
                ],
                peerUserId
              )}
              {/* If screen1 and screen2 are shared */}
              {renderVideoOrScreen(
                !peerscreenTwoStopped && !peerScreenStopped && peerVideoPaused,
                "w-1/2 border-[3px] border-white bg-white rounded-xl shadow-2xl",
                [
                  [
                    streamInstance.peerRemoteStream[peerUserId]?.screenStream,
                    mediaTypeConstant.screen,
                  ],
                  [
                    streamInstance.peerRemoteStream[peerUserId]
                      ?.screenTwoStream,
                    mediaTypeConstant.screentwo,
                  ],
                ],
                peerUserId
              )}
              {/* If screen2 and video are shared */}
              {renderVideoOrScreen(
                !peerscreenTwoStopped && !peerVideoPaused && peerScreenStopped,
                "w-1/2 border-[3px] border-white bg-white rounded-xl shadow-2xl",
                [
                  [
                    streamInstance.peerRemoteStream[peerUserId]
                      ?.screenTwoStream,
                    mediaTypeConstant.screentwo,
                  ],
                  [
                    streamInstance.peerRemoteStream[peerUserId]?.videoStream,
                    mediaTypeConstant.video,
                  ],
                ],
                peerUserId
              )}
              {/* If screen1 and video are shared */}
              {renderVideoOrScreen(
                !peerVideoPaused && !peerScreenStopped && peerscreenTwoStopped,
                "w-1/2 border-[3px] border-white bg-white rounded-xl shadow-2xl",
                [
                  [
                    streamInstance.peerRemoteStream[peerUserId]?.screenStream,
                    mediaTypeConstant.screen,
                  ],
                  [
                    streamInstance.peerRemoteStream[peerUserId]?.videoStream,
                    mediaTypeConstant.video,
                  ],
                ],
                peerUserId
              )}
              {/* If screen1, screen2, and video are shared */}
              {!peerVideoPaused &&
                !peerScreenStopped &&
                !peerscreenTwoStopped && (
                  <div className="h-full flex justify-center w-full bg-gray-100 rounded-xl">
                    <Video
                      className="w-1/2 "
                      src={
                        streamInstance.peerRemoteStream[peerUserId]
                          ?.screenStream
                      }
                      mediaType={mediaTypeConstant.screen}
                      userId={peerUserId}
                    />
                    <div className="h-1/4 w-1/4 absolute right-0 bottom-0 flex justify-end items-center gap-1">
                      <Video
                        className="w-1/2 border-[3px] bg-white border-white rounded-xl shadow-2xl"
                        src={
                          streamInstance.peerRemoteStream[peerUserId]
                            ?.screenTwoStream
                        }
                        mediaType={mediaTypeConstant.screentwo}
                        userId={peerUserId}
                      />
                      <Video
                        className="w-1/2 border-[3px] bg-white border-white rounded-xl shadow-2xl object-cover"
                        src={
                          streamInstance.peerRemoteStream[peerUserId]
                            ?.videoStream
                        }
                        mediaType={mediaTypeConstant.video}
                        userId={peerUserId}
                      />
                    </div>
                  </div>
                )}
            </>
          )}
          <p className="lg:text-[.6rem] xl:text-[.9rem] absolute bottom-1 left-1 text-white opacity-70">
            <span className="bg-gray-500 capitalize p-[.5px] px-[2px] backdrop-blur-sm rounded-md">{peerName}</span>
            {chatDetails.onlineUsers.some(user => user.peerUserId === peerUserId && user.emojiForDndPeer === emojiEventConst.DND) && (
              <span className="bg-red-600 px-2 ml-1 p-[.5px] rounded-md">DND</span>
            )}
            {chatDetails.onlineUsers.some(user => user.peerUserId === peerUserId && user.emojiForHandAndLunchPeer === emojiEventConst.LUNCH) && (
              <span className="bg-red-600 px-2 ml-1 p-[.5px] rounded-md">Lunch</span>
            )}
            {chatDetails.onlineUsers.some(user => user.peerUserId === peerUserId && user.emojiForHandAndLunchPeer === emojiEventConst.BRB) && (
              <span className="bg-red-600 px-2 ml-1 p-[.5px] rounded-md">BRB</span>
            )}
            {chatDetails.onlineUsers.some(user => user.peerUserId === peerUserId && user.emojiForHandAndLunchPeer === emojiEventConst.BREAK) && (
              <span className="bg-red-600 px-2 ml-1 p-[.5px] rounded-md">Break</span>
            )}
            {chatDetails.onlineUsers.some(user => user.peerUserId === peerUserId && user.emojiForMeetingPeer === emojiEventConst.MEETING) && (
              <span className="bg-red-600 px-2 ml-1 p-[.5px] rounded-md">Meeting</span>
            )}
          </p>
        </div>
      );
    }
  };

  return (
    <>
      <div className="h-full w-full rounded-xl flex justify-center items-center flex-wrap gap-1">
        {MULTIPLE_SUPERVISORS.status
          ? remotePeersId
            .filter(
              (id) =>
                remotePeers[id].peerType !== role.AGENT ||
                remotePeers[id].peerUserId === presenter
            )
            .map((id, index) => (
              <div
                style={{
                  width: `${getFlexWidthAndHeight(nonAgentPeersCount, index + 1).width
                    }%`,
                  height: `${getFlexWidthAndHeight(nonAgentPeersCount, index + 1)
                      .height
                    }%`,
                }}
                className="rounded-xl"
                key={remotePeers[id].peerUserId}
              >
                {renderPeerView(remotePeers[id])}
              </div>
            ))
          : presenter && (
            <div
              style={{
                width: `${getFlexWidthAndHeight(1, 1).width}%`,
                height: `${getFlexWidthAndHeight(1, 1).height}%`,
              }}
              className="rounded-xl"
              key={remotePeers[presenter]?.peerUserId}
            >
              {remotePeers[presenter] &&
                renderPeerView(remotePeers[presenter])}
            </div>
          )}
      </div>
    </>
  );
};

export default StudentScreen;
