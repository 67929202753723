import React, { useEffect, useRef } from 'react';
import Loader from '../Loader';
import { Navigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { RootState, store } from '../../app/store';
import { autoLoginUser } from '../../services/login';
import useQuery from '../../hooks/useQuery';
import routes from '../../constants/routes';
import { setViaApp } from '../../features/login/loginSlice';

const AutoLogin = () => {
  const queryParams = useQuery();

  let username = queryParams.get('username');
  let custCode = queryParams.get('custCode');

  const dispatch = useAppDispatch();

  const isLoggedIn = useAppSelector((state: RootState) => state.login.isLoggedIn);
  const autoLoginLoading = useAppSelector((state: RootState) => state.login.autoLoginLoading);
  const error = useAppSelector((state: RootState) => state.login.error);

  const hasAutoLoginBeenCalled = useRef(false);

  const isCheckLogginSync = useAppSelector((state: RootState) => state.login.isCheckLoginFulfilled);

  // useEffect(() => {
  //   if (isLoggedIn) {
  //     if(store.getState().login.viaApp === false){
  //       setTimeout(() => {
  //         dispatch(setViaApp());
  //       }, 3000);
  //     }
  //   } else {
  //     if(isCheckLogginSync && !hasAutoLoginBeenCalled.current){
  //       if (username && custCode) {
  //         const data = { username, password: "autologin", custCode };
  //         dispatch(autoLoginUser(data));
  //         hasAutoLoginBeenCalled.current = true;
  //       }
  //     }
  //   }
  // }, [isLoggedIn, dispatch, username, custCode, isCheckLogginSync]);

  if (isLoggedIn) {
    dispatch(setViaApp());
    return <Navigate to={routes.dashboard} replace />;
  } else if (isCheckLogginSync && !hasAutoLoginBeenCalled.current && username && custCode) {
    const data = { username, password: "autologin", custCode };
    dispatch(autoLoginUser(data));
    hasAutoLoginBeenCalled.current = true;
  } else if (error !== "" && !autoLoginLoading && !isLoggedIn) {
    return <Navigate to={routes.login} replace />;
  }
  
  // if (isLoggedIn) {
  //   return <Navigate to={routes.dashboard} replace />;
  // }

  // if (error !== "" && !autoLoginLoading) {
  //   return <Navigate to={routes.login} replace />;
  // }


  return (
    <div className="flex flex-col justify-center items-center">
      <div className="pt-10">
        <h1 className='text-center'>
          Welcome to <br /><b className='text-[25px] font-semibold text-[#9ABCFF]'>CollaborationRoom</b>
        </h1>
      </div>
      <div className="">
        <h4>Please wait while we log you into the application</h4>
      </div>
      <Loader />
    </div>
  );
};

export default AutoLogin;