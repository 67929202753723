import {
  inputResolutions,
  SegmentationConfig,
} from "../../helpers/segmentationHelper";
import { SourcePlayback } from "../../helpers/sourceHelper";
import { TFLite } from "../../interfaces/componentsInterface/mediaInterface";
import streamService from "../../services/streamService";

export function buildCanvas2dPipeline(
  sourcePlayback: SourcePlayback,
  segmentationConfig: SegmentationConfig,
  canvas: HTMLCanvasElement,
  tflite: TFLite,
  addFrameEvent: () => void,
  image: any,
  videoOption: 'blur' | 'background' | 'none' | 'normal'
) {
  const streamInstance = new streamService().getInstance();
  const ctx = canvas.getContext("2d")!;

  canvas.width = sourcePlayback.width;
  canvas.height = sourcePlayback.height;

  const [segmentationWidth, segmentationHeight] =
    inputResolutions[segmentationConfig.inputResolution];

  const segmentationMask = new ImageData(segmentationWidth, segmentationHeight);

  const segmentationMaskCanvas = new OffscreenCanvas(segmentationWidth, segmentationHeight);
  const segmentationMaskCtx = segmentationMaskCanvas.getContext("2d") as unknown as CanvasRenderingContext2D;

  const inputMemoryOffset = tflite._getInputMemoryOffset() / 4;
  const outputMemoryOffset = tflite._getOutputMemoryOffset() / 4;
  const segmentationPixelCount = segmentationWidth * segmentationHeight;

  let imageBitmap: ImageBitmap | null = null;
  let lastFrameTime = 0;
  const FRAME_INTERVAL = 100; // Capture frame every 100ms
  let currentBlurEffect: 'blur' | 'background' | 'none' | 'normal' = videoOption; // Store the current effect state

  async function render() {
    const now = performance.now();
    if (now - lastFrameTime < FRAME_INTERVAL) return; // Skip if interval not met
    lastFrameTime = now;

    const videoTrack = streamInstance.localVideoStream?.getVideoTracks()[0];
    if (!videoTrack) return;

    try {
      //@ts-ignore
      const imageCapture = new ImageCapture(videoTrack);
      imageBitmap = await imageCapture.grabFrame();
    } catch (error) {
      console.error("background/blur conversion error:", error);
      return;
    }

    resizeSource();
    addFrameEvent();
    runTFLiteInference();
    addFrameEvent();
    runPostProcessing();
  }

  function cleanUp() {
    imageBitmap = null;
  }

  function resizeSource() {
    if (!imageBitmap) return;

    segmentationMaskCtx.drawImage(
      imageBitmap,
      0, 0, sourcePlayback.width, sourcePlayback.height,
      0, 0, segmentationWidth, segmentationHeight
    );

    const imageData = segmentationMaskCtx.getImageData(0, 0, segmentationWidth, segmentationHeight);
    const data = imageData.data;

    for (let i = 0; i < segmentationPixelCount; i++) {
      const index = i * 4;
      tflite.HEAPF32[inputMemoryOffset + i * 3] = data[index] / 255;
      tflite.HEAPF32[inputMemoryOffset + i * 3 + 1] = data[index + 1] / 255;
      tflite.HEAPF32[inputMemoryOffset + i * 3 + 2] = data[index + 2] / 255;
    }
  }

  function runTFLiteInference() {
    tflite._runInference();

    for (let i = 0; i < segmentationPixelCount; i++) {
      const background = tflite.HEAPF32[outputMemoryOffset + i * 2];
      const person = tflite.HEAPF32[outputMemoryOffset + i * 2 + 1];
      const shift = Math.max(background, person);
      const backgroundExp = Math.exp(background - shift);
      const personExp = Math.exp(person - shift);

      segmentationMask.data[i * 4 + 3] = (255 * personExp) / (backgroundExp + personExp); // softmax
    }
    segmentationMaskCtx.putImageData(segmentationMask, 0, 0);
  }

  function runPostProcessing() {
    if (!imageBitmap) return;

    ctx.globalCompositeOperation = "copy";
    ctx.filter = "blur(8px)"; // FIXME Does not work on Safari

    drawSegmentationMask();
    ctx.globalCompositeOperation = "source-in";
    ctx.filter = "none";

    ctx.drawImage(imageBitmap, 0, 0);

    if (currentBlurEffect === "background") {
      backgroundChange();
    } else if (currentBlurEffect === "blur") {
      blurBackground();
    }
  }

  function drawSegmentationMask() {
    ctx.drawImage(
      segmentationMaskCanvas,
      0, 0, segmentationWidth, segmentationHeight,
      0, 0, sourcePlayback.width, sourcePlayback.height
    );
  }

  function blurBackground() {
    if (!imageBitmap) return;

    ctx.globalCompositeOperation = "destination-over";
    ctx.filter = "blur(20px)"; // FIXME Does not work on Safari
    ctx.drawImage(imageBitmap, 0, 0);
  }

  function backgroundChange() {
    ctx.globalCompositeOperation = "destination-over";
    const scale = Math.max(canvas.width / image.width, canvas.height / image.height);
    const x = canvas.width / 2 - (image.width / 2) * scale;
    const y = canvas.height / 2 - (image.height / 2) * scale;

    ctx.drawImage(image, x, y, image.width * scale, image.height * scale);
  }

  // Update blur effect state when video option changes
  function updateVideoOption(newOption: string) {
    currentBlurEffect = newOption as 'blur' | 'background' | 'none';
  }

  return { render, cleanUp, updateVideoOption };
}
