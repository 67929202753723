import RoomNavControls from "../../components/RoomNavControls";
import styles from "./styles.module.css";
import RoomSidebar from "../../components/RoomSidebar";
import { useEffect, useRef, useState } from "react";
import UserControls from "../../components/Usercontrols";
import InstructorScreen from "../../components/InstructorScreen";
import StudentScreen from "../../components/StudentScreen";
import MeetingRoomScreen from "../../components/MeetingRoomScreen";
import SocketService from "../../services/socket";
import MediasoupService from "../../services/mediasoupService";
import {
  AGENT_CAM_ALWAYS_ON,
  mediaTypeConstant,
  meetingRoomConstant,
  REACT_APP_CAMERA_OFF_NOTIFICATIONS,
  CAMERA_NOTIFICATION_TIMER_INTERVAL,
  resolutionConstant,
  role,
  roomViewModeConst,
  socketConnectiontype,
  socketPathName,
  AGENT_CAM_AUTO_ON,
  REACT_APP_HEADPOSE_SECONDS,
} from "../../utils/constant";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { clearCurrentRoom, roomSelector } from "../../features/room/roomSlice";
import { IConnectionDetails } from "../../interfaces/componentsInterface/chats";
import {
  getConnectionDetailsForMedia,
  getConnectionDetailsForDesktopApp,
} from "../../services/connectionDetails";
import streamService from "../../services/streamService";
import { deviceSelector } from "../../features/device/deviceSlice";
import {
  addEndIndexForRemotePeer,
  addRemotePeer,
  audioTurnOrOff,
  insertInstructorId,
  mediaSelector,
  removeInstructorId,
  prevPage,
  removeRemotePeer,
  setInstructorIds,
  setOrUnsetPresenter,
  setPage,
  setSocketId,
  setStartAndEndIndex,
  videoTurnOrOff,
  clearMediaReducer,
  setSelfPresenter,
  setSelfPresenterToInitialState,
  screenTurnOrOff,
  screenTwoTurnOrOff,
  setIsScreenAudioShared,
  setRemotePeersAllId,
  updateRemotePeer,
  updateOnlineUsersInMedia,
  setIsCamOffAlertFired,
  clearVideoPauseAlertInterval,
  setVideoPauseAlertInterval,
  setVideoAccessibleforPeer,
} from "../../features/media/mediaSlice";
import {
  RemotePeerData,
  TFLite,
} from "../../interfaces/componentsInterface/mediaInterface";
import { environmentSelector } from "../../features/environment/environmentSlice";
import { setFulfilled, setRole, userDetailsSelector } from "../../features/userDetails/userDetailsSlice";
import { constantMethod } from "../../utils/constantMethod";
import { store } from "../../app/store";
import Audio from "../../components/Audio";
import toast from "react-hot-toast";
import {
  addCollabScreenPeer,
  addScreenPeerWhileSocketConnected,
  clearMeetingRoomReducer,
  meetingRoomSelector,
  removeCollabScreenPeer,
} from "../../features/meetingRoom/meetingRoomSlice";
import {
  clearPrivateCallReducerState,
  privateCallSelector,
  setCallModal,
  setCaller,
  setIsPrivateCallOn,
  setOpenentCallerUserId,
  setPrivateCallPeerOne,
} from "../../features/privateCall/privateCallSlice";
import PrivateCallModal from "../../components/PrivateCallModal";
import {
  desktopAppSelector,
  setScreenNumObj,
} from "../../features/desktopApp/desktopAppSlice";
import {
  clearObserveReducer,
  observeSelector,
  setObserverModeOn,
  setOpenentObserveeAudioId,
  setOpenentObserverAudioId,
} from "../../features/observe/observeSlice";
import { useNavigate } from "react-router-dom";
import routes from "../../constants/routes";
import {
  addHighResUser,
  removeHighResUserStreamType,
  setScreenControlUsername,
} from "../../features/scaleResolution/scaleResolutionSlice";
import {
  clearReconnectionStatus,
  setIsReconnectionStatusAudio,
  setIsReconnectionStatusScreenForElectron,
  setIsReconnectionStatusScreenOne,
  setIsReconnectionStatusScreenTwo,
  setIsReconnectionStatusVideo,
  setReconnectionStatus,
  setReconnectionStatusInRoompage,
} from "../../features/reconnectionStatus/reconnectionStatusSlice";
import { clearLocalFileStream, setAudioFile, setLocalAudioFilePaused, setLocalScreenFileStopped } from "../../features/localFileStream/localFileStreamSlice";
import { convertIntoBoolean } from "../../utils/getboolean";
import { detect } from "../../services/faceDetection";
import {
  clearVerifyFace,
  setRecurrentCheck,
  setVerifyFace,
  setWrongPerson,
} from "../../features/verifyFace/verifyFaceSlice";
import config from "../../config";
import { getTFLiteModelFileName } from "../../helpers/segmentationHelper";
import { notifyMe } from "../../utils/desktopNotification";
import {
  clearVideoOption,
  setVideoOption,
  videoOptionSelector,
} from "../../features/videoOption/videoOptionSlice";
import { uploadFeedbackImage } from "../../services/feedbackImages";
import { ExclamationCircleIcon } from "@heroicons/react/20/solid";
import Button from "../../components/Button";
import { arrayMoveImmutable, arrayMoveMutable } from "array-move";
import {
  addEvent,
  clearAlerts,
  updateEvent,
} from "../../features/alerts/alertsSlice";
import { EventType } from "../../interfaces/slicesInterface/alerts";
import {
  clearScreenControl,
  setIsScreenControl,
  setOpenentPeerId,
} from "../../features/screenControl/screenControlSlice";
import {
  clearBackground,
  setImageLink,
  setIsVirtualModalOpen,
} from "../../features/videoBackground/videoBackgroundSlice";
import { setActivityPopup } from "../../features/activityChannel/activityChannelSlice";

import WebRTCIssueDetector, {
  QualityLimitationsIssueDetector,
  FramesDroppedIssueDetector,
  FramesEncodedSentIssueDetector,
  InboundNetworkIssueDetector,
  OutboundNetworkIssueDetector,
  NetworkMediaSyncIssueDetector,
  AvailableOutgoingBitrateIssueDetector,
  UnknownVideoDecoderImplementationDetector,
  IssueDetectorResult,
  NetworkScores,
} from "webrtc-issue-detector";
import watchRTC from "@testrtc/watchrtc-sdk";
import { troubleshootSelector } from "../../features/troubleshoot/troubleshoot";
import { clearPeerVolume, setPeerVolume } from "../../features/peerVolume/peerVolumeSlice";
import { resetChatListing, resetOnlineUsers } from "../../features/chat/chatSlice";
import { checkVideoDevices, getVideoStream } from "../../utils/AudioVideoDevices";
import { incrementDownCount, incrementUpCount, resetCounts } from "../../features/headPose/headPoseSlice";
import { shouldAddEvent } from "../../utils/shouldAddEvent";
const socketInstanceChat = new SocketService(
  socketConnectiontype.chat
).getInstance(socketConnectiontype.chat);
const socketInstanceAC = new SocketService(socketConnectiontype.activityChannel).getInstance(
  socketConnectiontype.activityChannel
);

const streamInstance = new streamService().getInstance();
const mediaSocketInstance = new SocketService(
  socketConnectiontype.media
).getInstance(socketConnectiontype.media);

const socketInstanceDesktopApp = new SocketService(
  socketConnectiontype.desktopApp
).getInstance(socketConnectiontype.desktopApp);

let replaceVideoTimeout: ReturnType<typeof setTimeout>;

const mediasoupInstance = new MediasoupService().getInstance();

declare function createTFLiteModule(): Promise<TFLite>;
declare function createTFLiteSIMDModule(): Promise<TFLite>;

export default function Room(): ReturnType<React.FC> {
  var frstart = useRef<any>(null);
  const navigate = useNavigate();
  const [active, setActive] = useState<boolean>(false);
  const [errorMessages, setErrorMessages] = useState("");
  const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(true);
  const [isAlertActive, setIsAlertActive] = useState<{
    val: boolean;
    count: number;
  }>({ val: false, count: 0 });
  const [cellphoneDetected, setCellphoneDetected] = useState(false);
  const [isParticipantActive, setIsParticipantActive] = useState<{
    val: boolean;
    count: number;
  }>({ val: false, count: 0 });
  const [segmentationConfig, setSegmentationConfig] = useState<any>({
    model: "meet",
    backend: "wasmSimd",
    inputResolution: "256x144",
    pipeline: "canvas2dCpu",
  });
  const [tflite, setTflite] = useState();
  const [tfliteSIMD, setTfliteSIMD] = useState<any>();
  const [selectedTFLite, setSelectedTFLite] = useState<any>();
  const [isSIMDSupported, setIsSIMDSupported] = useState<any>();
  const participantAlertCounterRef = useRef<number>(0);
  const consecutiveFalseCountersRef = useRef<number>(0);
  const [feedbackImageData, setFeedbackImageData] = useState<{
    file: File | any;
    agentUsername: string;
    parentRole: string;
    parentUsername: string;
    room: string;
    roomId:string;
    parentUserId:string;
    peerUserId:string;
  }>({
    file: "",
    agentUsername: "",
    parentRole: "",
    parentUsername: "",
    room: "",
    roomId:"",
    parentUserId:"",
    peerUserId:""
  });
  const cellPhoneDetectedUser = new Set();
  const multipleFaceUserRef = useRef<{ [key: string]: number }>({});
  // const [multipleFaceUser, setMultipleFaceUser] = useState<{[key:string]:number}>({});
  const feedbackImageDataRef = useRef(feedbackImageData);
  const camOnOffAlertIntervalRef = useRef() as any;

  const roomReducer = useAppSelector(roomSelector);
  const mediaReducer = useAppSelector(mediaSelector);
  const environmentReducer = useAppSelector(environmentSelector);
  const meetingRoomReducer = useAppSelector(meetingRoomSelector);
  const observeReducer = useAppSelector(observeSelector);
  const device = useAppSelector(deviceSelector);
  const user = useAppSelector(userDetailsSelector);
  const troubleshoot = useAppSelector(troubleshootSelector).troubleshoot;
  const videoOption = useAppSelector(videoOptionSelector);
  const [dndTime, setDndTime] = useState<number>(0);
  const [isDndRunning, setIsDndRunning] = useState<boolean>(false);
  const dndWorkerRef = useRef<Worker | null>(null);
  const [isDndModalVisible, setIsDndModalVisible] = useState<boolean>(false);
  let {
    userId: selfUserId,
    role: currentUserRole,
    username,
    enableDesktopApp,
  } = user;
  const privateCallReducer = useAppSelector(privateCallSelector);
  const screenControl = environmentReducer.environments.REACT_APP_SCREENCONTROL
    ? environmentReducer.environments.REACT_APP_SCREENCONTROL.status
    : enableDesktopApp ? enableDesktopApp : false;
  const dispatch = useAppDispatch();
  let messageSubscriber: any;
  let messageSubscriberDesktopApp: any;
  let messageSubscriberAC: any;
  let mediaSocket: SocketIOClient.Socket;
  const { MULTIPLE_SUPERVISORS } = environmentReducer.environments || {};
  let peerConnectionDesktopApp: any;
  let screenNumObj: any;
  var cellphoneTimeout = {} as any;
  const OFFER_OPTIONS = {
    offerToReceiveAudio: 0,
    offerToReceiveVideo: 1,
  };
  let webRtcIssueDetector: any;
  const DOWNLOAD_LINK = "https://collaborationcdn.nyc3.cdn.digitaloceanspaces.com/Remote/Collaborationroom-Setup-3.5.0.exe"

  const headPoseDetect =
    environmentReducer.environments &&
      environmentReducer.environments.REACT_APP_HEAD_POSE
      ? environmentReducer.environments.REACT_APP_HEAD_POSE.status
      : false;
  const faceDetectionFlag =
    environmentReducer.environments &&
      environmentReducer.environments.REACT_APP_FACE_DETECTION
      ? environmentReducer.environments.REACT_APP_FACE_DETECTION.status
      : false;
  const faceVerification = environmentReducer.environments
    .REACT_APP_FACE_VERIFICATION
    ? environmentReducer.environments.REACT_APP_FACE_VERIFICATION.status
    : false;

  const faceVerificationEnabled =
    user.faceVerification && user.faceVerification.isFaceVerificationEnabled;
  const isUserVerified =
    user.faceVerification && user.faceVerification.isVerified;

  const emotionDetectionAdminControl = environmentReducer.environments
    .EMOTION_DETECTION_ADMIN_CONTROL
    ? environmentReducer.environments.EMOTION_DETECTION_ADMIN_CONTROL.status
    : false;

  const cellphoneDetection = environmentReducer.environments
    .REACT_APP_CELLPHONE_DETECTION
    ? environmentReducer.environments.REACT_APP_CELLPHONE_DETECTION.status
    : false;

  const faceDetectionUrl =
    environmentReducer.environments.REACT_APP_FACE_DETECTION &&
    environmentReducer.environments.REACT_APP_FACE_DETECTION.value;

  const storeDetectionImages = environmentReducer.environments
    .STORE_DETECTION_IMAGES
    ? environmentReducer.environments.STORE_DETECTION_IMAGES
    : false;

  const detectionToken = environmentReducer.environments
    .REACT_APP_DETECTION_TOKEN
    ? environmentReducer.environments.REACT_APP_DETECTION_TOKEN.value
    : "";

  const cellphoneFaceDetectSeconds =
    environmentReducer.environments &&
      environmentReducer.environments.CELLPHONE_FACE_DETECTION_SECONDS
      ? environmentReducer.environments.CELLPHONE_FACE_DETECTION_SECONDS.value
      : 10;

  const downloadLink = environmentReducer.environments &&
      environmentReducer.environments.REACT_APP_DESKTOP_APP_DOWNLOAD_LINK
      ? environmentReducer.environments.REACT_APP_DESKTOP_APP_DOWNLOAD_LINK.value
      : DOWNLOAD_LINK

  const agentCamAlwaysOn = environmentReducer.environments.AGENT_CAM_ALWAYS_ON
      ? environmentReducer.environments.AGENT_CAM_ALWAYS_ON.status
      : AGENT_CAM_ALWAYS_ON;

  const agentCamAutoTurnOn = environmentReducer.environments.AGENT_CAM_AUTO_ON
      ? environmentReducer.environments.AGENT_CAM_AUTO_ON.status
      : AGENT_CAM_AUTO_ON;
  const isCameraNotificationsEnabled = environmentReducer.environments.REACT_APP_CAMERA_OFF_NOTIFICATIONS
      ? environmentReducer.environments.REACT_APP_CAMERA_OFF_NOTIFICATIONS.status
      : REACT_APP_CAMERA_OFF_NOTIFICATIONS;

  const cameraNotificationsTimerValue = environmentReducer.environments.REACT_APP_CAMERA_OFF_NOTIFICATIONS
      ? parseInt(environmentReducer.environments.REACT_APP_CAMERA_OFF_NOTIFICATIONS.value)
      : CAMERA_NOTIFICATION_TIMER_INTERVAL;

  const headPoseSeconds = environmentReducer.environments.REACT_APP_HEADPOSE_SECONDS ? environmentReducer.environments.REACT_APP_HEADPOSE_SECONDS.value : REACT_APP_HEADPOSE_SECONDS;
  useEffect(() => {


    mediaSocketInstance.messageSubscriber = mediaSocketInstance
      .getMessages()
      .subscribe((message: string) => {
        processMessage(JSON.parse(message));
      });
    // messageSubscriber =

    socketInstanceDesktopApp.messageSubscriber = socketInstanceDesktopApp
      .getMessages()
      .subscribe((message: string) => {
        processMessageDesktopApp(JSON.parse(message));
      });
    // messageSubscriberDesktopApp =
    fetchConnectionDetails();
    fetchConnectionDetailsForDesktopApp();
    loadTFLite();
  }, []);

  useEffect(() => {
    localStorage.removeItem("other-room");
    if (roomReducer.isAudioAllowedInRoom === false) {
      if (roomReducer.isAllowPrivateCallInSilentRoom === true) {
        toast.error(`Mic will be muted. Private call allowed.`);
      } else {
        toast.error("audio is not allowed in this room");
      }
    }

  }, []);


  useEffect(() => {
    localStorage.removeItem("ACPopOut");
    dispatch(setActivityPopup(false))
  }, [])


  const loadTFLite = async () => {
    if (createTFLiteModule) {
      createTFLiteModule().then((result) => {
        // @ts-ignore
        setTflite(result);
      });
      try {
        const createdTFLiteSIMD = await createTFLiteSIMDModule();
        setTfliteSIMD(createdTFLiteSIMD);
        setIsSIMDSupported(true);
      } catch (error) {
        console.warn("Failed to create TFLite SIMD WebAssembly module.", error);
      }
    }
  };
  useEffect(() => {
    feedbackImageDataRef.current = feedbackImageData;
  }, [feedbackImageData]);

  useEffect(() => {
    if (Notification.permission !== 'granted') {
      Notification.requestPermission().then((permission) => {
        if (permission === 'granted') {
          console.log('Notification permission granted.');
        } else {
          console.log('Notification permission denied.');
        }
      });
    }
  }, [])
  
  
  const sendPushNotification = async (peerName:any) => {
    if ('serviceWorker' in navigator && Notification.permission === 'granted') {
      navigator.serviceWorker.ready.then((registration) => {
        registration.showNotification('Incoming Call', {
          body: `${peerName} is calling. Do you want to accept or reject it?`,
          icon: '/favicon.ico',
          actions: [
            { action: 'acceptCall', title: 'Accept' },
            { action: 'rejectCall', title: 'Reject' },
          ],
          requireInteraction: true,     
          tag: 'incoming-call',       
        } as NotificationOptions);
      }).catch((error) => {
        console.error('Service Worker registration failed', error);
      });
    }
  };

  const loadTFLiteModel = async () => {
    console.log('JDS 55 loadTFLiteModel Called')
    if (
      !tflite ||
      (isSIMDSupported && !tfliteSIMD) ||
      (!isSIMDSupported && segmentationConfig.backend === "wasmSimd") ||
      (segmentationConfig.model !== "meet" &&
        segmentationConfig.model !== "mlkit")
    ) {
      console.log('JDS 55 RETURNING. MODEL NOT LOADED')
      return;
    }

    // streamInstance.selectedTflite = undefined;
    setSelectedTFLite(undefined);

    const newSelectedTFLite =
      segmentationConfig.backend === "wasmSimd" ? tfliteSIMD : tflite;

    if (!newSelectedTFLite) {
      console.log('JDS 55 newSelectedTFLite not found')
      throw new Error(
        `TFLite backend unavailable: ${segmentationConfig.backend}`
      );
    }

    const modelFileName = getTFLiteModelFileName(
      segmentationConfig.model,
      segmentationConfig.inputResolution
    );

    const modelResponse = await fetch(
      `${config.cdnUrl}/models/${modelFileName}.tflite`
    );

    const model = await modelResponse.arrayBuffer();
    console.log('JDS 55 MODEL: ', { model });
    const modelBufferOffset = newSelectedTFLite._getModelBufferMemoryOffset();
    console.log("Model buffer memory offset:", modelBufferOffset);
    console.log("Loading model buffer...");
    newSelectedTFLite.HEAPU8.set(new Uint8Array(model), modelBufferOffset);
    console.log(
      "_loadModel result:",
      newSelectedTFLite._loadModel(model.byteLength)
    );

    console.log("Input height:", newSelectedTFLite._getInputHeight());
    console.log("Input width:", newSelectedTFLite._getInputWidth());
    console.log("Input channels:", newSelectedTFLite._getInputChannelCount());

    console.log(
      "Output memory offset:",
      newSelectedTFLite._getOutputMemoryOffset()
    );
    console.log("Output height:", newSelectedTFLite._getOutputHeight());
    console.log("Output width:", newSelectedTFLite._getOutputWidth());
    console.log("Output channels:", newSelectedTFLite._getOutputChannelCount());

    console.log('JDS 55 BEFORE: TFLITE IN STREAM-INSTANCE: ', streamInstance.selectedTflite)
    streamInstance.selectedTflite = newSelectedTFLite;
    console.log('JDS 55 AFTER: TFLITE IN STREAM-INSTANCE: ', streamInstance.selectedTflite)
    setSelectedTFLite(newSelectedTFLite);
  };

  useEffect(() => {
    if (!selectedTFLite) {

      loadTFLiteModel();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tflite, tfliteSIMD, isSIMDSupported, segmentationConfig.model]);

  useEffect(() => {
    if (roomReducer.viewMode === roomViewModeConst.collabMode) {
      // Lower the video resolution because screen share started
      if (meetingRoomReducer.screenCollabPeerArray.length) {
        mediasoupInstance.scaleDownResolutionMethod(
          mediaTypeConstant.video,
          false,
          resolutionConstant.low
        );

        // Higher the video resolution because no one is sharing screen
      } else if (!meetingRoomReducer.screenCollabPeerArray.length) {
        if (!mediaReducer.localScreenStopped) {
          mediasoupInstance.scaleDownResolutionMethod(
            mediaTypeConstant.video,
            false,
            resolutionConstant.low
          );
        } else {
          mediasoupInstance.scaleDownResolutionMethod(
            mediaTypeConstant.video,
            false,
            resolutionConstant.high
          );
        }
      }
    }
  }, [meetingRoomReducer.screenCollabPeerArray]);

  // useEffect(() => {
  //   fetchConnectionDetailsForDesktopApp();
  // }, []);

  const webRtcIssue = () => {
    webRtcIssueDetector = new WebRTCIssueDetector({
      detectors: [
        new QualityLimitationsIssueDetector(),
        new FramesDroppedIssueDetector(),
        new FramesEncodedSentIssueDetector(),
        new InboundNetworkIssueDetector(),
        new OutboundNetworkIssueDetector(),
        new NetworkMediaSyncIssueDetector(),
        new AvailableOutgoingBitrateIssueDetector(),
        new UnknownVideoDecoderImplementationDetector(),
      ],
      getStatsInterval: 30_000, // set custom stats parsing interval
      onIssues: (payload: IssueDetectorResult) => {
        // callback for detected issues handling
        // Pass issue details to BE for futher processing
        console.log("WID webrtc issue: ", payload);
        mediaSocketInstance.sendMessage("WID_ISSUE_DETECTED", {
          userId: selfUserId,
          roomId: roomReducer.currentRoomId,
          payload,
        });
      },
      onNetworkScoresUpdated: (payload: NetworkScores) => {
        // callback for networks score updates handling
        // Pass Network Score to BE for futher processing'
        console.log("WID network score update: ", payload);
        if (payload.outbound !== undefined || payload.inbound !== undefined) {
          mediaSocketInstance.sendMessage("WID_NETWORK_SCORE", {
            userId: selfUserId,
            roomId: roomReducer.currentRoomId,
            payload,
          });
        }
      },
      ignoreSSRCList: [
        // in case you need to skip some ssrc from parsing, add its numbers to the array
      ],
    });
    console.log("webrtc issue detector is initiated");
    webRtcIssueDetector.watchNewPeerConnections();
  }

  const watchRTCTroubleshooter = () => {
    if (troubleshoot) {
      watchRTC.init();
      watchRTC.setConfig({
        rtcApiKey: `staging:${process.env.REACT_APP_RTC_API_KEY}`,
        rtcRoomId: roomReducer.currentRoom + username,
        rtcPeerId: username,
        rtcTags: [
          currentUserRole,
          username,
          roomReducer.currentRoom,
        ],
      });
    }
  }


  useEffect(() => {
    watchRTCTroubleshooter();
    webRtcIssue();
  }, [])

  useEffect(() => {

    return () => {

      const clearOnUnmount = async () => {
        await clearBeforeUnmount();
      }
      clearOnUnmount();
      console.log("hey check clear in room use Effect");
      clearInterval(frstart.current);
      if (replaceVideoTimeout) {
        clearTimeout(replaceVideoTimeout);
      }
      webRtcIssueDetector.stopWatchingNewPeerConnections();
      streamInstance.clearStreamService();
      // mediasoupInstance.clearMediaSoupService();
      // messageSubscriber.unsubscribe();
      // mediaSocketInstance.closeSocketConnection(true);
      // mediaSocketInstance.reconnectFlushState();
      // mediaSocketInstance.socket = undefined;
      // mediaSocketInstance.reconnectFlushState();
      // messageSubscriberDesktopApp.unsubscribe();
      // socketInstanceDesktopApp.closeSocketConnection(true);
      // socketInstanceDesktopApp.reconnectFlushState();
      // socketInstanceDesktopApp.socket = undefined;
      dispatch(clearPrivateCallReducerState());
      dispatch(clearMediaReducer());
      dispatch(clearMeetingRoomReducer());
      dispatch(clearReconnectionStatus());
      dispatch(clearAlerts());
      dispatch(clearObserveReducer());
      dispatch(clearCurrentRoom());
      cellPhoneDetectedUser.clear();
      dispatch(clearVideoOption())
      dispatch(clearBackground());
      dispatch(clearVerifyFace());
      dispatch(clearPeerVolume());
      dispatch(setFulfilled(false));
      dispatch(setRole(store.getState().user.permRole))
      // dispatch(clearVideoPauseAlertInterval());
      clearTimeout(camOnOffAlertIntervalRef.current);

    };
  }, []);


  const clearBeforeUnmount = async () => {

    try {

      const chatPromise = new Promise((resolve, reject) => {

        try {
          if (socketInstanceChat) {

            socketInstanceChat.sendMessage(
              "MANUAL_DISCONNECT_PEER",
              {}
            );
          }
          if (socketInstanceChat?.messageSubscriber) {
            socketInstanceChat.messageSubscriber.unsubscribe();
            socketInstanceChat.messageSubscriber = null;
          }

          if (socketInstanceChat) {
            socketInstanceChat.closeSocketConnection(true);
            socketInstanceChat.reconnectFlushState();
            socketInstanceChat.socket = undefined;
            dispatch(resetOnlineUsers());
            dispatch(resetChatListing());
            // closeChatsPopup();
          }


          resolve("Chat Disconnected");
        }
        catch (err: any) {
          reject(`An error occurred during Chat disconnection: ${err.message}`);
        }
      })


      const acPromise = new Promise((resolve, reject) => {
        try {
          if (socketInstanceAC) {
            socketInstanceAC.sendMessage("MANUAL_DISCONNECT_PEER", {})
          }


          if (socketInstanceAC?.messageSubscriber) {
            socketInstanceAC.messageSubscriber.unsubscribe();
            socketInstanceAC.messageSubscriber = null;
          }

          if (socketInstanceAC) {
            socketInstanceAC.closeSocketConnection(true);
            socketInstanceAC.reconnectFlushState();
            socketInstanceAC.socket = undefined;
          }

          resolve("Activity Channel Disconnected");
        } catch (err: any) {
          reject(`An error occurred  during AC disconnection: ${err.message}`);
        }
      })



      const mediaPromise = new Promise(async (resolve, reject) => {
        try {
          if (mediaSocketInstance) {
            mediaSocketInstance.sendMessage("MANUAL_DISCONNECT_PEER", {});
          }

          if (mediaSocketInstance?.messageSubscriber) {
            mediaSocketInstance.messageSubscriber.unsubscribe();
            mediaSocketInstance.messageSubscriber = null;
          }


          if (mediaSocketInstance) {
            await mediasoupInstance.clearMediaSoupService();
            mediaSocketInstance.closeSocketConnection(true);
            mediaSocketInstance.reconnectFlushState();
            mediaSocketInstance.socket = undefined;
          }

          resolve("Media Disconnected");
        } catch (err: any) {
          reject(`An error occurred during Media disconnection: ${err.message}`);
        }
      })


      const deskPromise = new Promise((resolve, reject) => {
        try {

          if (socketInstanceDesktopApp) {
            socketInstanceDesktopApp.sendMessage("MANUAL_DISCONNECT_PEER", {});
          }

          if (socketInstanceDesktopApp?.messageSubscriber) {
            socketInstanceDesktopApp.messageSubscriber.unsubscribe();
            socketInstanceDesktopApp.messageSubscriber = null;
          }


          if (socketInstanceDesktopApp) {
            socketInstanceDesktopApp.closeSocketConnection(true);
            socketInstanceDesktopApp.reconnectFlushState();
            socketInstanceDesktopApp.socket = undefined;
          }

          resolve("Desktop Disconnected");
        } catch (err: any) {
          reject(`An error occurred during Desktop disconnection: ${err.message}`);
        }
      })


      await Promise.all([chatPromise, acPromise, mediaPromise, deskPromise]);
      console.log("All promises resolved successfully.");
    } catch (err) {
      console.error("One or more promises failed:", err);
    }



  }

  const consumeStreamsInPausedMode = async (peer: RemotePeerData) => {
    if (peer.peerAudioProducing) {
      await streamInstance.consumeAudioStream(peer);
    }
    if (peer.peerVideoProducing) {
      await streamInstance.consumeVideoStream(peer);
    }
    if (peer.peerScreenProducing) {
      await streamInstance.consumeScreenStream(peer);
    }
    if (peer.peerScreenTwoProducing) {
      await streamInstance.consumeScreenTwoStream(peer);
    }
    if (peer.peerAudioFileProducing) {
      await streamInstance.consumeAudioFileStream(peer);
    }

    if (peer.audioSystemProducing) {
      await streamInstance.consumeSystemAudioStream(peer);
    }
  };

  const openElectronApp = () => {

    let hostname = process.env.REACT_APP_DESK_WS_URL!;

    if (!hostname) {
      if (window.location.origin.includes("localhost")) {
        hostname = "http://localhost:8085";
      } else {
        hostname = window.location.origin; // Default to the current origin
      }
    }


    window.open(
      `${window.location.origin}/launchApp/${roomReducer.currentRoomId}/${roomReducer.currentRoom}?download=${downloadLink}`,
      "pagename",
      `resizable,height=500,width=650`
    );

    setTimeout(() => {
      let value = localStorage.getItem(selfUserId + "screencontrol");
      if (value === selfUserId) {
        streamInstance.startScreenStream(
          roomReducer.currentRoomId,
          currentUserRole
        );
        localStorage.removeItem(selfUserId + "screencontrol");
      }
    }, 3000);
  };
  useEffect(() => {
    console.log({ feedbackImageData });
  }, [feedbackImageData]);

  const getPagePeers = () => {
    let media = store.getState().media;

    // Return Supervisor & Presenter peers only to Agents so that layout can be maintained on agent side
    if (
      MULTIPLE_SUPERVISORS.status &&
      roomReducer.viewMode !== roomViewModeConst.collabMode &&
      currentUserRole === role.AGENT
    ) {
      let filteredPeers: Array<string> = [];
      filteredPeers = [...media.instructorPeersId];

      if (
        mediaReducer.remotePeers[media.presenter] &&
        mediaReducer.remotePeers[media.presenter].peerType === role.AGENT
      ) {
        filteredPeers = [
          ...filteredPeers,
          mediaReducer.remotePeers[media.presenter].peerUserId,
        ];
      }

      if (media.currentPage === 1) {
        return filteredPeers.slice(0, media.totalPage - 1);
      }
      const startIndex = (media.currentPage - 1) * (media.totalPage - 1);
      const endIndex = media.currentPage * media.totalPage - media.currentPage;
      return filteredPeers.slice(startIndex, endIndex);
    }

    return media.remotePeersId.slice(
      media.startIndexForRemotePeer,
      media.endIndexForRemotePeer
    );
  };

  // const performRightShiftByOne = (peerUserId: string) => {
  //   let mediaReducer = getMediaReducer();
  //   let meetingReducer = getMeetingRoomReducer();
  //   if (!constantMethod.checkHigherRole(currentUserRole)) {
  //     return;
  //   }





  //   let pageSize = roomReducer.perPagePeerNum;

  //   if (
  //     roomReducer.viewMode === roomViewModeConst.collabMode &&
  //     meetingReducer.screenCollabPeer.peerId !== ""
  //   ) {
  //     pageSize =
  //       +environmentReducer.environments.REACT_APP_PAGE_SIZE_COLLAB.value;
  //   } else {
  //     pageSize = +environmentReducer.environments.REACT_APP_PAGE_SIZE.value;
  //   }

  //   if (peerUserId === selfUserId) {
  //     return;
  //   }
  //   const pagePeers = mediaReducer.remotePeersId.slice(0, pageSize)

  //   if (pagePeers.includes(peerUserId)) {
  //     const peerIndex = mediaReducer.remotePeersId.findIndex(
  //       (remotePeerUserId: string) => remotePeerUserId === peerUserId
  //     );
  //     let updatedPeers = arrayMoveImmutable(
  //       mediaReducer.remotePeersId,
  //       peerIndex,
  //       0
  //     );
  //     console.log({updatedPeers});
  //     dispatch(setRemotePeersAllId(updatedPeers));
  //     return;
  //   }
  //   console.log(peerUserId, selfUserId, peerUserId === selfUserId);
  //   if (
  //     mediaReducer.remotePeersId &&
  //     mediaReducer.remotePeersId.length >= pageSize
  //   ) {
  //     // create a sub array
  //     const currentPagePeers = getPagePeers();
  //     let peerFoundInCurrentPage = false;
  //     for (let i = 0; i < currentPagePeers.length; i++) {
  //       if (currentPagePeers[i] === peerUserId) {
  //         peerFoundInCurrentPage = true;
  //         break;
  //       }
  //     }

  //     if (!peerFoundInCurrentPage) {
  //       // find peers index first
  //       const peerIndex = mediaReducer.remotePeersId.findIndex(
  //         (remotePeerUserId: string) => remotePeerUserId === peerUserId
  //       );
  //       let arrayValueAfterChangeValue = arrayMoveImmutable(
  //         mediaReducer.remotePeersId,
  //         peerIndex,
  //         0
  //       );
  //       console.log(arrayValueAfterChangeValue);
  //       dispatch(setRemotePeersAllId(arrayValueAfterChangeValue));
  //       mediaReducer = store.getState().media;
  //       streamInstance.resumeConsumersForPeer([peerUserId]);
  //       const peerToBePaused = mediaReducer.remotePeersId[pageSize];
  //       streamInstance.pauseConsumersForPeer([peerToBePaused]);
  //     }
  //   }
  // };


  const performRightShiftByOne = (peerUserId: string, fromHandRaise: boolean) => {
    let mediaReducer = getMediaReducer();
    let meetingReducer = getMeetingRoomReducer();

    if (!constantMethod.checkHigherRole(currentUserRole) && !fromHandRaise) {
      return;
    }

    let pageSize = roomReducer.perPagePeerNum;

    // Determine page size based on room mode
    if (
      roomReducer.viewMode === roomViewModeConst.collabMode &&
      meetingReducer.screenCollabPeer.peerId !== ""
    ) {
      pageSize =
        +environmentReducer.environments.REACT_APP_PAGE_SIZE_COLLAB.value;
    } else {
      pageSize = +environmentReducer.environments.REACT_APP_PAGE_SIZE.value;
    }

    if (peerUserId === selfUserId) {
      return;
    }

    // Find the index of peerUserId in the remotePeersId list
    const peerIndex = mediaReducer.remotePeersId.findIndex(
      (remotePeerUserId: string) => remotePeerUserId === peerUserId
    );

    // If peerUserId is not found, return early
    if (peerIndex === -1) {
      console.log("Peer not found in remotePeersId list");
      return;
    }

    // Move the peer to the first position in the remotePeersId list
    const updatedPeers = arrayMoveImmutable(
      mediaReducer.remotePeersId,
      peerIndex,
      0
    );

    console.log({ updatedPeers });
    dispatch(setRemotePeersAllId(updatedPeers));

    if (peerIndex < pageSize) {
      return;
    }

    // Refresh mediaReducer after dispatch
    mediaReducer = store.getState().media;
    const currentPagePeers = getPagePeers();

    // Resume the stream for the first peer (moved peer)
    streamInstance.resumeConsumersForPeer(currentPagePeers);
    const filteredPeerIdsForPaused = updatedPeers.filter(peer => !currentPagePeers.includes(peer))
    // Pause streams for peers that were moved outside the first page
    // for (let i = pageSize; i < updatedPeers.length; i++) {
    // const peerToBePaused = updatedPeers[i];
    streamInstance.pauseConsumersForPeer(filteredPeerIdsForPaused);
    // }

  };
  const getMediaReducer = () => {
    return store.getState().media;
  };

  const getChatReducer = () => {
    return store.getState().chat;
  };

  const getRemotePeer = (peerUserId: string) => {
    let remotePeers = store.getState().media.remotePeers;
    return remotePeers[peerUserId] ? { ...remotePeers[peerUserId] } : undefined;
  };
  const getMeetingRoomReducer = () => {
    return store.getState().meetingRoom;
  };
  const getPrivateCallReducer = () => {
    return store.getState().privateCall;
  };

  const getObserveReducer = () => {
    return store.getState().observe;
  };
  const getScaleResolutionReducer = () => {
    return store.getState().scaleResolution;
  };

  const getLocalFileStream = () => {
    return store.getState().localfilestreamstatus;
  };

  const getreconnectionStatus = () => {
    return store.getState().reconnectionStatus;
  };

  const removePeerFromReduxAndStreamInstance = (peerUserId: string) => {
    let meetingRoomReducer = getMeetingRoomReducer();
    let pageNumAccordingToRoomType =
      meetingRoomReducer.screenCollabPeerArray.length > 0
        ? +environmentReducer.environments.REACT_APP_PAGE_SIZE_COLLAB.value
        : +environmentReducer.environments.REACT_APP_PAGE_SIZE.value;
    dispatch(removeRemotePeer({ peerUserId: peerUserId, perPagePeerNum: pageNumAccordingToRoomType }));
    if (store.getState().screenControl.openentPeerId === peerUserId) {
      dispatch(setIsScreenControl(false));
      dispatch(setOpenentPeerId(""));
    }
    if (
      streamInstance.peerRemoteStream &&
      streamInstance.peerRemoteStream[peerUserId]
    ) {
      delete streamInstance.peerRemoteStream[peerUserId];
    }

    streamInstance.resumeConsumersForPeer(getPagePeers());
  };

  const goToDashboard = () => {
    navigate(routes.dashboard);
  };

  const processACMessages = (message: any) => {
    console.log({ message });
  };
  const processMessage = async (message: any) => {
    let mediaReducer = getMediaReducer();
    let chatReducer = getChatReducer();
    let meetingRoomReducer = getMeetingRoomReducer();
    let privateCallReducer = getPrivateCallReducer();
    let observeReducer = getObserveReducer();
    let scaleResolutionReducer = getScaleResolutionReducer();
    let localFileStreamReducer = getLocalFileStream();
    let reconnectionStatusReducer = getreconnectionStatus();

    switch (message.type) {
      case "SOCKET_CONNECTED":
        if (mediaSocketInstance.ifReconnect) {
          console.log(`Network unstable, reconnecting`);

          if (chatReducer.emojiIconMain && chatReducer.emojiIconMain !== "") {
            console.log({iconMain:chatReducer});
            console.log("resume the emoji event");
            if(chatReducer.emojiIconMain === "BRB"){
              mediaSocketInstance.sendMessage("USER_ACTIVITY_EMOJI_FOR_ICON", {
                roomId: roomReducer.currentRoomId,
                type: "berightback",
                peerUserId: user.userId,
              });
            }else if(chatReducer.emojiIconMain === "DND"){
              mediaSocketInstance.sendMessage("USER_ACTIVITY_EMOJI_FOR_DND", {
                roomId: roomReducer.currentRoomId,
                type: chatReducer.emojiIconMain,
                peerUserId: user.userId,
              });
            }else if (chatReducer.emojiIconMain === "Meeting"){
              mediaSocketInstance.sendMessage("USER_ACTIVITY_EMOJI_FOR_MEETING", {
                roomId: roomReducer.currentRoomId,
                type: chatReducer.emojiIconMain,
                peerUserId: user.userId,
              }); 
            }else {
              mediaSocketInstance.sendMessage("USER_ACTIVITY_EMOJI_FOR_BREAKANDLUNCH", {
                roomId: roomReducer.currentRoomId,
                type: chatReducer.emojiIconMain,
                peerUserId: user.userId,
              });
            }
          }
          mediaSocketInstance.ifReconnect = false;
          dispatch(setReconnectionStatusInRoompage(true));

          if (!mediaReducer.localAudioPaused) {
            dispatch(setIsReconnectionStatusAudio(true));
          }


          if (
            (convertIntoBoolean(screenControl) || user.enableDesktopApp) &&
            !constantMethod.checkHigherRole(currentUserRole)
          ) {
            dispatch(setIsReconnectionStatusScreenForElectron(true));
            socketInstanceDesktopApp.ifReconnect = true;
          } else {
            if (!mediaReducer.localScreenStopped) {
              dispatch(setIsReconnectionStatusScreenOne(true));
            }
            if (!mediaReducer.localScreenStoppedTwo) {
              dispatch(setIsReconnectionStatusScreenTwo(true));
            }
          }
          if (!mediaReducer.localVideoPaused) {
            dispatch(setIsReconnectionStatusVideo(true));
          }
          dispatch(clearScreenControl());

          dispatch(clearMeetingRoomReducer());
          // this.props.clearActivityReducer();
          dispatch(setVideoOption("normal"));
          dispatch(setIsVirtualModalOpen(false));
          dispatch(setImageLink(""));
          // this.props.setReconnectionStatus(true);

          await mediasoupInstance.clearMediaSoupService();
          // if (streamInstance.videoTrack) {
          //   streamInstance.videoTrack.stop();
          //   streamInstance.videoTrack = null;
          // }
          dispatch(clearMediaReducer());
          dispatch(clearObserveReducer());
          dispatch(clearPrivateCallReducerState());
          streamInstance.peerRemoteStream = {};
          streamInstance.disconnectLocalHark();
          // const mediaStream = await getVideoStream();
          // mediaStream.getTracks().forEach(track => track.stop());
        }

        mediaSocketInstance.sendMessage("NOTIFICATIONS_LEAVE_AND_ENTER", {
          msg: `${user.username} has joined the room`,
          peerName: `${user.firstName} ${user.lastName}`,
        });
        reconnectionStatusReducer = getreconnectionStatus();
        if (reconnectionStatusReducer.reconnectionStatusInRoompage) {
          if (
            (!convertIntoBoolean(screenControl) && !user.enableDesktopApp)  || constantMethod.checkHigherRole(currentUserRole)
          ) {
            streamInstance.localScreenStream = localFileStreamReducer.isVideoFile
              ? undefined
              : streamInstance.localScreenStream;
          } else {
            dispatch(screenTurnOrOff(true));
            dispatch(screenTwoTurnOrOff(true));
          }
        }

        dispatch(clearLocalFileStream());
        // await streamInstance.checkVideoDeviceStatus()

        let presenter = message.presenter ? message.presenter : "";
        let pageNumAccordingToRoomType =
          message.screenCollabPeerArray.length > 0
            ? +environmentReducer.environments.REACT_APP_PAGE_SIZE_COLLAB.value
            : +environmentReducer.environments.REACT_APP_PAGE_SIZE.value;
        dispatch(
          addScreenPeerWhileSocketConnected(message.screenCollabPeerArray)
        );
        dispatch(setOrUnsetPresenter(presenter));
        dispatch(setInstructorIds(message.instructorIdsArray));
        mediaSocket = mediaSocketInstance.socket as SocketIOClient.Socket;
        dispatch(setSocketId(mediaSocket.id));
        mediaSocketInstance.disconnectSocketId = mediaSocket.id;
        dispatch(addEndIndexForRemotePeer(pageNumAccordingToRoomType));
        await mediasoupInstance.getRouterRtpCaps();
        await mediasoupInstance.createPublishingTransport(mediaSocket);
        let videoDeviceFound = await checkVideoDevices();
        // await streamInstance.startVideoStream(device.activeVideoDevice, faceDetection);

        mediaSocketInstance.sendMessage("ADD_NEW_PEER",{
          peerUserId: selfUserId,
          peerName: user.firstName + " " + user.lastName,
          peerUsername: user.username,
        })

        //  if (videoDeviceFound) {
        //         // console.log({ videoInUse: this._videoInUse })
        //         if (streamInstance._videoInUse) {
        //           console.log("HELLLLLLLLLLLLLLLLLLLLLLLll")
        //           store.dispatch(setVideoAccessibleforPeer(false))
        //           mediaSocketInstance.sendMessage("SET_VIDEO_ACCESSIBLE", {
        //             userId: selfUserId,
        //             roomId: roomReducer.currentRoomId,
        //             videoAccessible: false
        //           })

        //         }
        //  }
        if (roomReducer.isAudioAllowedInRoom || roomReducer.isAllowPrivateCallInSilentRoom) {
          streamInstance.startAudioStream(device.activeAudioDevice);
        }

        if (
          !constantMethod.checkHigherRole(user.role) && store.getState().chat.emojiIconMain==="" &&
          (store.getState().environment.environments?.AGENT_CAM_AUTO_ON
            ?.status ||
            store.getState().environment.environments?.AGENT_CAM_ALWAYS_ON
              ?.status) //AGENT_CAM_ALWAYS_ON
        ) {
          streamInstance.startVideoStream(device.activeVideoDevice,faceDetection)
          faceDetection();
        }else if(store.getState().reconnectionStatus.isReconnectionStatusVideo){
          streamInstance.startVideoStream(device.activeVideoDevice,faceDetection)

        }
        await mediasoupInstance.createSubscribingTransport(mediaSocket);
        const joinedPeers = (await mediaSocketInstance.sendMessage(
          "GET_JOINED_PEERS",
          {
            roomname: roomReducer.currentRoom,
            roomId: roomReducer.currentRoomId,
          }
        )) as Record<string, Record<string, string | boolean>[]>;

        console.log("socket_connected array", joinedPeers.peersArray.length);
        if (
          joinedPeers &&
          joinedPeers.peersArray &&
          joinedPeers.peersArray.length > 0
        ) {
          await Promise.all(
            joinedPeers.peersArray.map(async (peer: any) => {
              let remotePeerObj = {
                ...peer,
                peerAudioPaused: true,
                peerVideoPaused: true,
                peerAudioFilePaused: true,
                peerScreenStopped: true,
                peerscreenTwoStopped: true,


              };

              dispatch(
                addRemotePeer({
                  remotePeer: remotePeerObj,
                  perPagePeerNum: pageNumAccordingToRoomType,
                })
              );

              await consumeStreamsInPausedMode(remotePeerObj);
            })
          );
          if ((constantMethod.convertIntoBoolean(screenControl) || user.enableDesktopApp) && constantMethod.checkHigherRole(currentUserRole))  {
            mediaReducer = getMediaReducer();
          }


          if (
            constantMethod.checkHigherRole(currentUserRole) ||
            roomReducer.viewMode === roomViewModeConst.collabMode
          ) {
            const pagePeers = getPagePeers();
            streamInstance.resumeConsumersForPeer(pagePeers);
          } else if (
            MULTIPLE_SUPERVISORS.status &&
            currentUserRole === role.AGENT
          ) {
            mediaReducer = getMediaReducer();
            let pagePeerUserIds: Array<string> = [];
            pagePeerUserIds = message.instructorIdsArray;
            mediaReducer = getMediaReducer();
            if (
              mediaReducer.remotePeers[presenter] &&
              mediaReducer.remotePeers[presenter]?.peerType === role.AGENT
            ) {
              pagePeerUserIds = [
                ...pagePeerUserIds,
                mediaReducer.remotePeers[presenter]?.peerUserId as string,
              ];
            }
            streamInstance.resumeConsumersForPeer(pagePeerUserIds);
          } else {
            if (presenter !== "") {
              await streamInstance.resumeConsumersForPeer([presenter]);
            }
          }
        }

        if (reconnectionStatusReducer.reconnectionStatusInRoompage) {
          socketInstanceDesktopApp.socketReconnect();

          if (reconnectionStatusReducer.isReconnectionStatusScreenOne) {

            streamInstance.startScreenStream(
              roomReducer.currentRoomId,
              currentUserRole
            );
          }
          if (reconnectionStatusReducer.isReconnectionStatusScreenTwo) {
            streamInstance.startScreenTwo(
              roomReducer.currentRoomId,
              currentUserRole
            );
          }
          dispatch(setReconnectionStatusInRoompage(false));
          dispatch(setReconnectionStatus(true));
        }

        if (isCameraNotificationsEnabled && !store.getState().reconnectionStatus.reconnectionStatus && !agentCamAlwaysOn && !agentCamAutoTurnOn && (mediaReducer.localVideoPaused && !constantMethod.checkHigherRole(currentUserRole))) {
          chatReducer = getChatReducer();
          console.log({kkkk0_OFF : store.getState().reconnectionStatus.reconnectionStatus});
            camOnOffAlertIntervalRef.current = setTimeout(() => {
              mediaSocketInstance.sendMessage("GET_ALERT_NOTIFICATION_FOR_CAMERA_ON_OFF", {
                msg:"OFF",
                peerUserId : selfUserId
              })
              toast.dismiss();
              toast.success("Your Camera is Off");
              notifyMe("Your Camera is Off");
              store.dispatch(setIsCamOffAlertFired(true));
              clearTimeout(camOnOffAlertIntervalRef.current);
              camOnOffAlertIntervalRef.current = undefined;

            }, Number(cameraNotificationsTimerValue * 60 * 1000))
        }

        break;

      case "NEW_PRESENTER":
        // DO NOT UNCOMMENT ANYTHING. THINGS WILL BREAK
        if (currentUserRole === role.AGENT) {
          let presenterPeer = mediaReducer.remotePeers[mediaReducer.presenter];

          let promotePeer = mediaReducer.remotePeers[message.peerUserId];

          dispatch(setSelfPresenterToInitialState());

          if (mediaReducer.presenter === selfUserId) {
            if (currentUserRole === role.AGENT) {
              let video = false;
              let screen = false;
              let screenTwo = false;
              let allThreeGet = false;

              // eslint-disable-next-line @typescript-eslint/no-redeclare
              for (var key in scaleResolutionReducer.highResUser) {
                if (scaleResolutionReducer.highResUser.hasOwnProperty(key)) {
                  if (
                    scaleResolutionReducer.highResUser[key].includes(
                      mediaTypeConstant.screen
                    )
                  ) {
                    screen = true;
                  }
                  if (
                    scaleResolutionReducer.highResUser[key].includes(
                      mediaTypeConstant.screentwo
                    )
                  ) {
                    screenTwo = true;
                  }
                  if (
                    scaleResolutionReducer.highResUser[key].includes(
                      mediaTypeConstant.video
                    )
                  ) {
                    video = true;
                  }

                  if (video && screen && screenTwo) {
                    allThreeGet = true;
                    break;
                  }
                }
              }

              if (!allThreeGet) {
                if (!video) {
                  mediasoupInstance.scaleDownResolutionMethod(
                    mediaTypeConstant.video,
                    false,
                    resolutionConstant.low
                  );
                }
                if (!screen) {
                  mediasoupInstance.scaleDownResolutionMethod(
                    mediaTypeConstant.screen,
                    false,
                    resolutionConstant.low
                  );
                }
                if (!screenTwo) {
                  mediasoupInstance.scaleDownResolutionMethod(
                    mediaTypeConstant.screentwo,
                    false,
                    resolutionConstant.low
                  );
                }
              }
            }
          }

          if (!mediaReducer.presenter) {
            dispatch(setOrUnsetPresenter(message.peerUserId));
          }

          if (!mediaSocket || promotePeer) {
            streamInstance.resumeConsumersForPeer([message.peerUserId]);
            dispatch(setOrUnsetPresenter(message.peerUserId));
          }

          if (selfUserId === message.peerUserId) {
            // for agent who has been promoted
            dispatch(setOrUnsetPresenter(message.peerUserId));

            mediasoupInstance.scaleDownResolutionMethod(
              "",
              true,
              resolutionConstant.high
            );
          }

          if (presenterPeer) {
            if (!MULTIPLE_SUPERVISORS.status) {
              // if Already promoted user is agent
              if (selfUserId === message.peerUserId) {
                // if (constantMethod.checkHigherRole(presenterPeer.peerType)) {
                streamInstance.resumeConsumersForPeer([
                  message.allInstructor[0],
                ]);

                /*
                 * Self user is presenter BUT set instructor to state so that video can shown in layout because
                 * in studentScreen condition is implemented on remotePeers & if we set self id to presenter that
                 * condition will return false (because remotepeers dont have self user data) so video will not be displayed.
                 * SO, If self user is presenter then show first instructor from instrucotr array to self.
                 */
                dispatch(setOrUnsetPresenter(message.allInstructor[0]));

                // Flag for self user if he is presenter, so that we can show text: You are the presenter
                dispatch(setSelfPresenter());

                // streamInstance.pauseConsumersForPeer([
                //   presenterPeer.peerUserId,
                // ]);
                // }
              } else if (message.peerUserId !== presenterPeer.peerUserId) {
                streamInstance.pauseConsumersForPeer([
                  presenterPeer.peerUserId,
                ]);
              }
            } else {
              if (selfUserId === message.peerUserId) {
                streamInstance.resumeConsumersForPeer([message.peerUserId]);
                dispatch(setOrUnsetPresenter(message.peerUserId));
              }
              // if (constantMethod.checkHigherRole(presenterPeer.peerType)) {
              //   streamInstance.pauseConsumersForPeer([
              //     presenterPeer.peerSessionId,
              //   ]);
              //   dispatch(setOrUnsetPresenter(""));
              // }
            }
          }
        } else {
          // this.performRightShiftByOne(message.peerSessionId);
          dispatch(setOrUnsetPresenter(message.peerUserId));
        }
        break;
      case "NEW_PRESENTER_PROMOTED_AGENT":
        if (currentUserRole === role.AGENT) {
          // if (
          //   this.state.presenterForAgent === this.roomSocket.id &&
          //   this.state.presenter === message.peerSessionId &&
          //   !this.multipleSupervisors
          // ) {
          //   this.resumeConsumersForPeer([message.allInstructor[0]]);
          //   this.setState({
          //     presenter: message.allInstructor[0],
          //   });
          // }
        }
        break;

        case "PRODUCE_STOP_REMOTE_VIDEO":


          if(message.produceVideo){

            
             await streamInstance.startVideoStream(device.activeVideoDevice,faceDetection,message.remoteActionFrom)
            
          }else{
            mediasoupInstance.videoProducer?.close();
            if (streamInstance.localVideoStream) {
              streamInstance.localVideoStream.getVideoTracks().forEach(function (track: any) {
                track.stop();
              });
              streamInstance.localVideoStream = null;
            }
      streamInstance.pauseVideo(user.userId);
      if (streamInstance.videoTrack) {
        streamInstance.videoTrack.stop();
        streamInstance.videoTrack = null;
      }
      // setShowVideoOptions(false);
      // clearInterval(props.frstart.current);
          }

        break;

      case "FEEDBACK_IMG_UPLOAD":
        console.log("inside roompagetsx");
        if (message.agentUsername === user.username) {
          if (videoOption.videoOption === "normal") {
            let canvas: any = document.getElementById("canvas");
            const video: any = document.getElementsByClassName(
              "face_detection_node"
            )[0];

            if (video && video.videoWidth && video.videoHeight) {
              console.log("inside roompagetsx 2");
              canvas.width = video.videoWidth;
              canvas.height = video.videoHeight;
              canvas
                .getContext("2d")
                .drawImage(video, 0, 0, video?.videoWidth, video?.videoHeight);
              canvas.toBlob((blob: any) => {
                console.log(blob);
                // const img = new Image();
                // img.src = window.URL.createObjectURL(blob);
                // document.body.appendChild(img);
                const updatedData = {
                  file: blob,
                  agentUsername: message.agentUsername,
                  parentRole: message.parentRole,
                  parentUsername: message.parentUsername,
                  room: message.room,
                  roomId: message.roomId,
                  parentUserId:message.parentUserId,
                  peerUserId:message.peerUserId
                };
                setFeedbackImageData(updatedData);

                // Update the ref directly
                feedbackImageDataRef.current = updatedData;
              });
            } else {
              console.log("inside roompagetsx 3");
              // send error message to admin the feedback image cannot be captured
            }
          } else {
            console.log("inside roompagetsx 4");
            let canvas: any = document.getElementById("canvas");
            const input: any = document.getElementsByClassName(
              "face_detection_node"
            )[0];

            if (input) {
              canvas.width = input.videoWidth;
              canvas.height = input.videoHeight;
              const offscreen = document.createElement("canvas");
              offscreen.width = canvas.width;
              offscreen.height = canvas.height;
              const ctx = offscreen.getContext("2d");
              ctx?.drawImage(input, 0, 0, canvas.width, canvas.height);
              console.log("inside roompagetsx 5");

              offscreen.toBlob((blob: any) => {
                const updatedData = {
                  file: blob,
                  agentUsername: message.agentUsername,
                  parentRole: message.parentRole,
                  parentUsername: message.parentUsername,
                  room: message.room,
                  roomId: message.roomId,
                  parentUserId:message.parentUserId,
                  peerUserId:message.peerUserId
                };
                setFeedbackImageData(updatedData);

                // Update the ref directly
                feedbackImageDataRef.current = updatedData;
              });
            }
          }
        }
        break;

      case "FEEDBACK_IMG_UPLOAD_API":
        console.log("HUHUHU", { message });
        if (message.agentUsername === user.username) {
          const { file, agentUsername, parentRole, parentUsername, room ,roomId ,parentUserId ,peerUserId} =
            feedbackImageDataRef.current;

          console.log("EVENTHHH", feedbackImageDataRef.current);

          uploadFeedbackImage({
            file,
            agentUsername,
            parentRole,
            parentUsername,
            room,
            roomId,
            peerUserId,
            parentUserId,
            comment: message.comment,
          });
        }
        break;

      case "SHIFT_TO_ANOTHER_ROOM":
        console.log("SHIFTU", { message });

        
        localStorage.setItem(username, message.roomId);
        // localStorage.setItem(username,message.roomname);
        localStorage.setItem(`${username}viewMode`, message.viewMode);
        localStorage.setItem(`other-room`, "true");

        navigate(routes.dashboard);
        toast.success(`You are being shifted to ${message.roomname}`);
        break;

      case "MUTE_ALL_PARTICIPANT":
        if (
          !mediaReducer.localAudioPaused &&
          !privateCallReducer.isPrivateCallOn &&
          observeReducer.openentObserverAudioId === ""
        ) {
          streamInstance.pauseLocalMic(selfUserId);

          toast.success(`All participants have muted`);
        }
        break;
      case "NETWORK_STATUS":
        let networkPeer = getRemotePeer(message.peerUserId);

        if (networkPeer && message.peerUserId) {
          let networkPeerData = {
            networkStrength: message.networkStrength,
          };
          dispatch(
            updateRemotePeer({
              peerUserId: message.peerUserId,
              remoteUserData: networkPeerData,
            })
          );
        }

        break;

      case "NOTIFICATIONS_LEAVE_AND_ENTER":
        if (message && message.peerUsername !== user.username) {
          dispatch(
            addEvent({
              userId: message.userId,
              event: EventType.RoomEnter,
              timestamp: Date.now(),
              startTime: Date.now(),
              peerName: message.peerName,
              peerUsername: message.peerUsername,
            })
          );
        }

        console.log("NOTIFICATIONXX", { message });

        break;

      case "FACE_DETECTED":
        console.log({ message });
        let facePeer = { ...mediaReducer.remotePeers[message.peerUserId] };

        if (facePeer && message.peerUserId) {
          let facePeerData = {
            expression: message.expression,
            numfaces: message.numfaces,
            headPose: message.headPose,
            headDownCount: message.headDownCount,
            headUpCount: message.headUpCount,
          };
          dispatch(
            updateRemotePeer({
              peerUserId: message.peerUserId,
              remoteUserData: facePeerData,
            })
          );

          if (constantMethod.checkHigherRole(user.role)) {
            const multipleFaceUser = multipleFaceUserRef.current;
            if (message.numfaces > 1) {
              if (multipleFaceUser.hasOwnProperty(message.peerUserId)) {
                if (
                  multipleFaceUser[message.peerUserId] !==
                  Number(message.numfaces)
                ) {
                  multipleFaceUser[message.peerUserId] = Number(
                    message.numfaces
                  );
                  dispatch(
                    addEvent({
                      userId: message.peerUserId,
                      event: EventType.FaceDetection,
                      peerName: message.peerName,
                      peerUsername: message.peerUsername,
                      timestamp: Date.now(),
                      startTime: Date.now(),
                      message: message.msg,
                    })
                  );
                }
              } else {
                multipleFaceUser[message.peerUserId] = Number(message.numfaces);
                dispatch(
                  addEvent({
                    userId: message.peerUserId,
                    event: EventType.FaceDetection,
                    peerName: message.peerName,
                    peerUsername: message.peerUsername,
                    timestamp: Date.now(),
                    startTime: Date.now(),
                    message: message.msg,
                  })
                );
              }
            } else {
              multipleFaceUser.hasOwnProperty(message.peerUserId) &&
                delete multipleFaceUser[message.peerUserId];
            }
          }

          // expression: "neutral"
          // msg: "There are 1 person on the screen"
          // numfaces: 1
          // peerName: "neeraj verma"
          // peerSessionId: "MEnwESU2YXZESThUAAAf"
          // peerType: "Agent"
          // peerUserId: "663a1e991c40f2edae252f0d"
          // peerUsername: "NEERAJ"
          // time: 1720436286351
          // type
          // : "FACE_DETECTED"
          // videoPause: false
        }

        break;

      case "CELLPHONE_DETECTED":
        console.log("cell", { message });

        let cellPhonePeer = { ...mediaReducer.remotePeers[message.peerUserId] };

        if (cellPhonePeer && message.peerUserId) {
          if (cellphoneTimeout[message.peerUserId]) {
            clearTimeout(cellphoneTimeout[message.peerUserId]);
          }

          let cellPhonePeerData = {
            objectDetect: message.score,
          };
          dispatch(
            updateRemotePeer({
              peerUserId: message.peerUserId,
              remoteUserData: cellPhonePeerData,
            })
          );

          if (
            user.userId !== message.peerUserId &&
            !cellPhoneDetectedUser.has(message.peerUserId)
          ) {
            cellPhoneDetectedUser.add(message.peerUserId);

            dispatch(
              addEvent({
                userId: message.peerUserId,
                event: EventType.CellphoneDetection,
                peerName: message.peerName,
                peerUsername: message.peerUsername,
                timestamp: Date.now(),
                startTime: Date.now(),
              })
            );
          }

          //           cellPhoneConfidence: 0.92
          // cellphone: "cell-phone"
          // peerName: "neeraj verma"
          // peerSessionId: "NP1Sa8EC2UDjpJEKAAAn"
          // peerType: "Agent"
          // peerUserId: "663a1e991c40f2edae252f0d"
          // peerUsername: "NEERAJ"
          // score: true
          // startDate: 1720437132370
          // type: "CELLPHONE_DETECTED"
        }

        cellphoneTimeout[message.peerUserId] = setTimeout(() => {
          dispatch(
            updateRemotePeer({
              peerUserId: message.peerUserId,
              remoteUserData: { objectDetect: 0 },
            })
          );
          cellPhoneDetectedUser.delete(message.peerUserId);
        }, (parseInt(cellphoneFaceDetectSeconds) + 3) * 1000);

        break;

    
        case "SET_VIDEO_ACCESSIBLE":
        console.log({message})
        

          toast.error(`${message.peerName}'s Camera is not accessible`);
        
        break;
    
        case "VERIFY_FACE":
        console.log("cell", { message });
        let verifyFacePeer = {
          ...mediaReducer.remotePeers[message.peerUserId],
        };

        if (verifyFacePeer && message.peerUserId) {
          let verifyFacePeerData = {
            faceVerificationSuccess: message.faceVerificationSuccess,
            isNotSamePerson: message.isNotSamePerson,
            showAlertInParticipant: message.showAlertInParticipant,
          };
          dispatch(
            updateRemotePeer({
              peerUserId: message.peerUserId,
              remoteUserData: verifyFacePeerData,
            })
          );
        }

        break;

      case "ERROR":
        setErrorMessages(message.message);

        break;
      case "PEER_EXIT":
        goToDashboard();
        break;
      case "OFFLINE":
        {
          let peer = mediaReducer.remotePeers[message.peerUserId];
          if (peer) {
            if (message.peerUsername !== user.username) {
              dispatch(
                addEvent({
                  userId: message.peerUserId,
                  event: EventType.RoomLeave,
                  timestamp: Date.now(),
                  startTime: Date.now(),
                  peerName: message.peerName,
                  peerUsername: message.peerUsername,
                })
              );
              const events = ["BRB", "Break", "Lunch", "DND", "Meeting"];
              const alerts = store.getState().alerts.alerts;

              for (let key in alerts) {
                const [userId, Event, time] = key.split("_");
                if (!alerts[key].endTime && events.includes(Event)) {
                  dispatch(
                    updateEvent({
                      userId: userId,
                      event: Event,
                      endTime: Date.now(),
                    })
                  );
                }
              }
            }

            if (constantMethod.checkHigherRole(peer.peerType)) {
              dispatch(removeInstructorId(message.peerUserId));
            }



            let collabpeerId = meetingRoomReducer.screenCollabPeer.peerId;
            let collabpeerLength =
              meetingRoomReducer.screenCollabPeerArray.length;

            // console.log(collabpeerId, collabpeerLength, "check collab peer")

            if (scaleResolutionReducer.highResUser[message.peerUsername]) {
              dispatch(
                removeHighResUserStreamType({
                  username: message.peerUsername,
                  streamType: [],
                })
              );
              if (currentUserRole === role.AGENT) {
                let video = false;
                let screen = false;
                let screenTwo = false;
                let allThreeGet = false;

                // eslint-disable-next-line @typescript-eslint/no-redeclare
                for (var key in scaleResolutionReducer.highResUser) {
                  if (scaleResolutionReducer.highResUser.hasOwnProperty(key)) {
                    if (
                      scaleResolutionReducer.highResUser[key].includes(
                        mediaTypeConstant.screen
                      )
                    ) {
                      screen = true;
                    }
                    if (
                      scaleResolutionReducer.highResUser[key].includes(
                        mediaTypeConstant.screentwo
                      )
                    ) {
                      screenTwo = true;
                    }
                    if (
                      scaleResolutionReducer.highResUser[key].includes(
                        mediaTypeConstant.video
                      )
                    ) {
                      video = true;
                    }

                    if (video && screen && screenTwo) {
                      allThreeGet = true;
                      break;
                    }
                  }
                }

                if (!allThreeGet) {
                  if (!video) {
                    mediasoupInstance.scaleDownResolutionMethod(
                      mediaTypeConstant.video,
                      false,
                      resolutionConstant.low
                    );
                  }
                  if (!screen) {
                    mediasoupInstance.scaleDownResolutionMethod(
                      mediaTypeConstant.screen,
                      false,
                      resolutionConstant.low
                    );
                  }
                  if (!screenTwo) {
                    mediasoupInstance.scaleDownResolutionMethod(
                      mediaTypeConstant.screentwo,
                      false,
                      resolutionConstant.low
                    );
                  }
                }
              }
            }

            // if (message.peerUsername === this.state.screenControlUsername) {
            //   this.setState({ screenControlUsername: "" });
            // }
            if (message.peerUserId) {
              // toast.success(`${message.peerUsername} has left the room`);

              mediaSocketInstance.sendMessage("REMOVE_CONSUMERS_FOR_PEER", {
                userId: message.peerUserId,
              });
            }

            // let existParticipant = this.props.breakParticipant.find(
            //   (val: any) => val.peerId === message.peerSessionId
            // );

            // if (this.props.breakParticipant.length > 0 && existParticipant) {
            //   this.props.setorRemoveTempRoomUser(
            //     message.peerSessionId,
            //     message.peerUsername,
            //     this.props.breakParticipant
            //   );
            // }

            // if screen shared peer and consume by peer has been disconnected
            if (collabpeerId !== "") {
              screenStopAndOfflinePeerForCollab(
                message.peerUserId,
                meetingRoomConstant.offline
              );
            }

            if (collabpeerId !== message.peerUserId) {
              removePeerFromReduxAndStreamInstance(message.peerUserId);
            }

            if (
              (collabpeerLength !== 1 || collabpeerId !== message.peerUserId) &&
              mediaReducer.currentPage > 1
            ) {
              if (getPagePeers().length === 0) {
                if (mediaReducer.startIndexForRemotePeer > 0) {
                  let previousPageUserIds = getPagePeers();
                  dispatch(
                    prevPage({ perPagePeerNum: roomReducer.perPagePeerNum })
                  );
                  let currentPageUserIds = getPagePeers();
                  streamInstance.resumeConsumersForPeer(currentPageUserIds);
                  streamInstance.pauseConsumersForPeer(previousPageUserIds);
                }
              }
            }
          }
        }
        break;

      case "CALL_RECIEVING":
        if (message.peers) {
          const rp = getRemotePeer(message.peers.remoteUserId);

          const rp2 = getRemotePeer(message.peers.ownUserId);

          if (rp) {
            let rpData = { isCallModalOn: true };
            dispatch(
              updateRemotePeer({
                peerUserId: rp.peerUserId,
                remoteUserData: rpData,
              })
            );
          }

          if (rp2) {
            let rpData2 = { isCallModalOn: true };
            dispatch(
              updateRemotePeer({
                peerUserId: rp2.peerUserId,
                remoteUserData: rpData2,
              })
            );
          }

          if (message.peers.ownUserId === selfUserId) {
            await sendPushNotification(rp?.peerName);
            if (!message.observeflag) {
              if (observeReducer.openentObserverAudioId !== "") {
                mediaSocketInstance.sendMessage("STOP_OBSERVE_AUDIO_AGENT", {
                  roomname: roomReducer.currentRoom,
                  userId: observeReducer.openentObserverAudioId,
                  from: "privateagent",
                });
              } else if (observeReducer.openentObserveeAudioId !== "") {
                mediaSocketInstance.sendMessage("STOP_OBSERVE_AUDIO_AGENT", {
                  roomname: roomReducer.currentRoom,
                  userId: observeReducer.openentObserveeAudioId,
                  from: "privatesupervisor",
                });
              }
            }
            dispatch(
              setCallModal({
                ...privateCallReducer.callModalOption,
                isCallModal: true,
                peerFirstName: `${rp?.peerName.split(" ")[0]}`,
                peerName: `${rp?.peerName}`,
                callAcceptedButton: true,
                idForMakingCall: message.peers.remoteUserId,
              })
            );

            // if (document.hidden !== undefined && document.hidden) {
            toast.success(`${rp?.peerName} calling...`);

            // this.notifyMe(`${rp?.peerName} calling...`);
            // }
          }
        }
        break;

      case "PRIVATE_CALL_STARTED":
        if (message.peers) {
          let peer1 = getRemotePeer(message.peers[0]);
          let peer2 = getRemotePeer(message.peers[1]);

          if (peer1) {
            let peerOneData = {
              privateCallPeerOne: peer2?.peerName,
              isPrivateCallOn: true
            };
            dispatch(
              updateRemotePeer({
                peerUserId: peer1.peerUserId,
                remoteUserData: peerOneData,
              })
            );
          }
          if (peer2) {
            let peerTwoData = {
              privateCallPeerOne: peer1?.peerName,
              isPrivateCallOn: true
            };
            dispatch(
              updateRemotePeer({
                peerUserId: peer2.peerUserId,
                remoteUserData: peerTwoData,
              })
            );
          }
          if (
            message.peers[0] === selfUserId ||
            message.peers[1] === selfUserId
          ) {
            dispatch(setIsPrivateCallOn(true));
            dispatch(setPrivateCallPeerOne(peer1?.peerName));

            let peerName = peer1 ? peer1.peerName : peer2?.peerName;
            let peerId = peer1 ? peer1.peerUserId : peer2?.peerUserId;
            dispatch(setOpenentCallerUserId(peerId));
            if (mediaReducer.localAudioPaused) {
              streamInstance.unpauseLocalMic(selfUserId);
            }
            notifyMe(`You are now in a private call with ${peerName}`);

            // toast.success(`You are now in a private call with ${peerName}`);
          }

          let socketData: any = {
            roomname: roomReducer.currentRoom,
            roomId: roomReducer.currentRoomId,
            consumersToBePaused: message.peers,
          };
          if (message.peers[0] === selfUserId) {
            socketData.callAt = new Date();
          }
          mediaSocketInstance.sendMessage(
            "PAUSE_AUDIO_CONSUMERS_FOR_PEER",
            socketData
          );
        }
        break;

      case "PRIVATE_CALL_ENDED":
        if (message.privateStoppedPeer) {
          let peer1 = getRemotePeer(message.privateStoppedPeer[0]);
          let peer2 = getRemotePeer(message.privateStoppedPeer[1]);

          if (
            message.privateStoppedPeer[0] === selfUserId ||
            message.privateStoppedPeer[1] === selfUserId
          ) {
            dispatch(setIsPrivateCallOn(false));
            dispatch(setOpenentCallerUserId(""));
            dispatch(setPrivateCallPeerOne(""));
            dispatch(
              setCallModal({
                isCallModal: false,
                idForMakingCall: "",
                peerFirstName: "",
                callAcceptedButton: false,
                peerName: "",
                peerRemoteUserId: "",
              })
            );

            let peerName = peer1 ? peer1.peerName : peer2?.peerName;

            notifyMe(`Your private call with ${peerName} has ended`);
            streamInstance.pauseLocalMic(selfUserId);
            dispatch(setPeerVolume({ peerUserId: selfUserId, volume: -100 }))

            // toast.success(`Your private call with ${peerName} has ended`);

            if (privateCallReducer.imCaller) {
              mediaSocketInstance.sendMessage(
                "PRIVATE_CALL_ENDED_ACKNOWLEDGE",
                {
                  callEndAt: new Date(),
                }
              );
              dispatch(setCaller(false));
            }
          }

          if (peer1) {
            let peerOneData = {
              privateCallPeerOne: "",
              isPrivateCallOn: false,
              isCallModalOn: false
            }
            dispatch(
              updateRemotePeer({
                peerUserId: peer1.peerUserId,
                remoteUserData: peerOneData,
              })
            );
          }

          if (peer2) {
            let peerTwoData = {
              privateCallPeerOne: "",
              isPrivateCallOn: false,
              isCallModalOn: false
            }
            dispatch(
              updateRemotePeer({
                peerUserId: peer2.peerUserId,
                remoteUserData: peerTwoData,
              })
            );
          }
        }
        break;

      case "CALL_REJECTED":
        if (message.peers) {
          let rp = getRemotePeer(message.peers.remoteUserId);
          let rp2 = getRemotePeer(message.peers.ownUserId);

          if (rp) {
            let rpData = { isCallModalOn: false };
            dispatch(
              updateRemotePeer({
                peerUserId: rp.peerUserId,
                remoteUserData: rpData,
              })
            );
          }

          if (rp2) {
            let rpTwoData = { isCallModalOn: false };
            dispatch(
              updateRemotePeer({
                peerUserId: rp2.peerUserId,
                remoteUserData: rpTwoData,
              })
            );
          }

          if (message.peers.ownUserId === selfUserId) {
            dispatch(setIsPrivateCallOn(false));
            dispatch(setOpenentCallerUserId(""));
            dispatch(setPrivateCallPeerOne(""));
            dispatch(
              setCallModal({
                isCallModal: false,
                idForMakingCall: "",
                peerFirstName: "",
                callAcceptedButton: false,
                peerName: "",
                peerRemoteUserId: "",
              })
            );

            if (message.peers.typeAction === "CALL_REJECTED") {
              toast.error(`Call rejected by ${rp?.peerName}`);
              notifyMe(`Call rejected by ${rp?.peerName}`);
            } else if (message.peers.typeAction === "CALL_DID_NOT_PICKUP") {
              toast.error(
                `${rp?.peerName} is not available right`
              );
              notifyMe(
                `${rp?.peerName} is not available right`
              );
            }else if(message.peers.typeAction==="CALL_CUT_BY_CALLER" && rp){
              toast.error(`Missed private call from ${rp?.peerName}`);
              notifyMe(`Missed private call from ${rp?.peerName}`);
              dispatch(
                addEvent({
                  userId: message.peers.remoteUserId,
                  event: EventType.MissedCall,
                  timestamp: Date.now(),
                  startTime: Date.now(),
                  peerName: rp?.peerName,
                  peerUsername: rp?.peerUsername,
                })
              );
            }
          }
          if (message.peers.remoteUserId === selfUserId && rp2) {
            if (message.peers.typeAction === "CALL_DID_NOT_PICKUP") {
              toast.error(`Missed private call from ${rp2?.peerName}`);
              notifyMe(`Missed private call from ${rp2?.peerName}`);
              dispatch(
                addEvent({
                  userId: message.peers.ownUserId,
                  event: EventType.MissedCall,
                  timestamp: Date.now(),
                  startTime: Date.now(),
                  peerName: rp2?.peerName,
                  peerUsername: rp2?.peerUsername,
                })
              );
            }
          }
        }
        break;
      case "CALL_DISCONNECTED":
        if (message.peers) {
          let rp2 = getRemotePeer(message.peers.ownUserId);

          if (rp2) {
            let rpTwoData = { isCallModalOn: false };
            dispatch(
              updateRemotePeer({
                peerUserId: rp2.peerUserId,
                remoteUserData: rpTwoData,
              })
            );
          }

          if (privateCallReducer.imCaller) {
            mediaSocketInstance.sendMessage("PRIVATE_CALL_ENDED_ACKNOWLEDGE", {
              callEndAt: new Date(),
              callAction: "USER_DISCONNECTED",
            });
            dispatch(setCaller(false));
          }

          if (message.peers.ownUserId === selfUserId) {
            dispatch(setIsPrivateCallOn(false));
            dispatch(setOpenentCallerUserId(""));
            dispatch(setPrivateCallPeerOne(""));
            dispatch(
              setCallModal({
                isCallModal: false,
                idForMakingCall: "",
                peerFirstName: "",
                callAcceptedButton: false,
                peerName: "",
                peerRemoteUserId: "",
              })
            );
          }
        }
        break;

      case "PRIVATE_CALL_DISCONNECTED":
        if (message.peerPrivateUserId) {
          let peer1 = getRemotePeer(message.peerPrivateUserId);

          if (message.peerPrivateUserId === selfUserId) {
            dispatch(setIsPrivateCallOn(false));
            dispatch(setOpenentCallerUserId(""));
            dispatch(setPrivateCallPeerOne(""));
            dispatch(
              setCallModal({
                isCallModal: false,
                idForMakingCall: "",
                peerFirstName: "",
                callAcceptedButton: false,
                peerName: "",
                peerRemoteUserId: "",
              })
            );

            streamInstance.pauseLocalMic(selfUserId);
            notifyMe(
              `Your private call with ${message.disconnectedPeerName} has ended`
            );
            toast.success(
              `Your private call with ${message.disconnectedPeerName} has ended`
            );
          }

          if (peer1) {
            let peerOne = {
              privateCallPeerOne: "",
              isPrivateCallOn: false
            };
            dispatch(
              updateRemotePeer({
                peerUserId: peer1.peerUserId,
                remoteUserData: peerOne,
              })
            );
          }
        }
        break;
      case "OBSERVER_AUDIO_STARTED":
        if (message.observeeUserId && message.observerUserId) {
          let observeePeer = getRemotePeer(message.observeeUserId);
          let observerPeer = getRemotePeer(message.observerUserId);

          if (observeePeer) {
            let observeePeerData = { openentObserverAudioId: message.observerUserId };
            dispatch(
              updateRemotePeer({
                peerUserId: observeePeer.peerUserId,
                remoteUserData: observeePeerData,
              })
            );
          }

          if (observerPeer) {
            let observerPeerData = { openentObserveeAudioId: message.observeeUserId };
            dispatch(
              updateRemotePeer({
                peerUserId: observerPeer.peerUserId,
                remoteUserData: observerPeerData,
              })
            );
          }

          mediaSocketInstance.sendMessage("PAUSE_AUDIO_CONSUMERS_FOR_PEER", {
            roomname: roomReducer.currentRoom,
            consumersToBePaused: [
              message.observeeUserId,
              message.observerUserId,
            ],
          });

          // @ts-ignore
          if (message.observeeUserId === selfUserId) {
            dispatch(setOpenentObserverAudioId(message.observerUserId));

            // Process Audio that we got with screen stream from electron
            streamInstance.processAudioOfAgent();
            if (mediasoupInstance && mediasoupInstance.audioProducer) {
              mediasoupInstance.audioProducer.resume();
            }
          }
          dispatch(setObserverModeOn(true));

          if (message.observerUserId === selfUserId) {
            dispatch(setOpenentObserveeAudioId(message.observeeUserId));
          }
        }
        break;

      case "STOP_OBSERVER_AUDIO":
        if (message.observeeUserId && message.observerUserId) {
          let observeePeer = getRemotePeer(message.observeeUserId);
          let observerPeer = getRemotePeer(message.observerUserId);

          if (observeePeer) {
            let observeePeerData = { openentObserverAudioId: "" };
            dispatch(
              updateRemotePeer({
                peerUserId: observeePeer.peerUserId,
                remoteUserData: observeePeerData,
              })
            );
          }

          if (observerPeer) {
            let observerPeerData = { openentObserveeAudioId: "" };
            dispatch(
              updateRemotePeer({
                peerUserId: observerPeer.peerUserId,
                remoteUserData: observerPeerData,
              })
            );
          }

          // @ts-ignore
          if (message.observeeUserId === selfUserId) {
            dispatch(setOpenentObserverAudioId(""));
            if (
              message.from !== "unmuteagent" &&
              mediasoupInstance &&
              mediasoupInstance.audioProducer
            ) {
              mediasoupInstance.audioProducer.pause();
            }

            // Replace Audio track from 'elctron audio' to local 'mic audio'
            if (streamInstance.localAudioStream) {
              let track = streamInstance.localAudioStream.getAudioTracks()[0];
              mediasoupInstance.replaceAudioTrack(track);
            }
          }
          dispatch(setObserverModeOn(false));

          if (message.observerUserId === selfUserId) {
            dispatch(setOpenentObserveeAudioId(""));

            // this.notificationForObservation(
            //   message.from,
            //   observeePeer?.peerUsername
            // );
          }

          // socketInstance.sendMessage("RESUME_AUDIO_CONSUMERS_OF_OBSERVEE", {
          //   roomname: this.roomname,
          //   observeeId: message.observeeId,
          // });
        }
        break;

      case "SETTING_SCALE_RESOLUTION_DOWN_FOR_SELECTED_MEDIA_TYPE":
        if (
          currentUserRole === role.AGENT &&
          mediaReducer.presenter !== selfUserId
        ) {
          let screenLowFalse = false;
          let highResUser = scaleResolutionReducer.highResUser[message.userName]
            ? [...scaleResolutionReducer.highResUser[message.userName]]
            : undefined;

          if (
            message.screenControl &&
            scaleResolutionReducer.screenControlUsername !== "" &&
            scaleResolutionReducer.screenControlUsername !== message.userName
          ) {
            screenLowFalse = true;
          } else if (message.screenControl) {
            if (message.resolution === resolutionConstant.high) {
              dispatch(setScreenControlUsername(message.userName));
            } else {
              dispatch(setScreenControlUsername(""));
              for (var key in highResUser) {
                if (highResUser.hasOwnProperty(key)) {
                  // @ts-ignore
                  if (highResUser[key].includes(message.mediaType)) {
                    screenLowFalse = true;
                    break;
                  }
                }
              }
            }
          } else {
            if (highResUser) {
              if (highResUser.includes(message.mediaType)) {
                const index = highResUser.indexOf(message.mediaType);

                if (index > -1) {
                  // only splice array when item is found
                  highResUser.splice(index, 1); // 2nd parameter means remove one item only

                  dispatch(
                    removeHighResUserStreamType({
                      username: message.userName,
                      streamType: highResUser,
                    })
                  );
                }
                if (
                  message.mediaType === mediaTypeConstant.screen &&
                  scaleResolutionReducer.screenControlUsername !== ""
                ) {
                  screenLowFalse = true;
                }
                scaleResolutionReducer = getScaleResolutionReducer();

                for (var key in scaleResolutionReducer.highResUser) {
                  if (scaleResolutionReducer.highResUser.hasOwnProperty(key)) {
                    if (
                      scaleResolutionReducer.highResUser[key].includes(
                        message.mediaType
                      )
                    ) {
                      screenLowFalse = true;
                      break;
                    }
                  }
                }
              } else {
                dispatch(
                  addHighResUser({
                    username: message.userName,
                    streamType: [...highResUser, message.mediaType],
                  })
                );
              }
            } else {
              dispatch(
                addHighResUser({
                  username: message.userName,
                  streamType: [message.mediaType],
                })
              );
            }
          }

          if (!screenLowFalse) {
            mediasoupInstance.scaleDownResolutionMethod(
              message.mediaType,
              false,
              message.resolution
            );
          }
        }
        break;

      case "CHECK_CONSUMER":
        console.log("checkingconsumer", message.status);

        break;

      case "GET_ALERT_NOTIFICATION_FOR_CAMERA_ON_OFF":
        if(isCameraNotificationsEnabled && constantMethod.checkHigherRole(currentUserRole)){
          toast.success(`${message.peerName}'s camera is ${message.msg}`);
          notifyMe(`${message.peerName}'s camera is ${message.msg}`);
          if (shouldAddEvent(store.getState().alerts.alerts, message.userId, EventType.CamOnOff, `Camera is ${message.msg}`)) {
            dispatch(
              addEvent({
                userId: message.userId,
                event: EventType.CamOnOff,
                peerName: message.peerName,
                peerUsername: message.peerUsername,
                timestamp: Date.now(),
                startTime: Date.now(),
                message: `Camera is ${message.msg}`,
              })
            );
          }
        }
        break;

      case "PEER_STATUS_CHANGE":
        console.log(mediaReducer, "done");
        console.log(message.peerUserId, "done");
        let peer = mediaReducer.remotePeers[message.peerUserId];

        if(!mediasoupInstance.publishTransport || !mediasoupInstance.subscribeTransport ){
          break;
        }
        if (!peer && selfUserId !== message.peerUserId) {
          peer = {
            peerSessionId: message.peerSessionId,
            peerUsername: message.peerUsername,
            peerName: message.peerName,
            peerType: message.peerType,
            peerUserId: message.peerUserId,
            imageUrl: message.imageUrl,
            enableDesktopApp:message.enableDesktopApp,
            videoAccessible:message.videoAccessible,
            peerAudioProducing: false,
            peerVideoProducing: false,
            peerScreenProducing: false,
            peerScreenTwoProducing: false,
            peerAudioFileProducing: false,
            peerAudioPaused: true,
            peerVideoPaused: true,
            peerAudioFilePaused: true,
            peerScreenStopped: true,
            peerscreenTwoStopped: true,
            audioSystemProducing: false,
            systemAudioProducerRole: "",
            openentObserveeAudioId: "",
            openentObserverAudioId: "",
            isPrivateCallOn: false,
            privateCallPeerOne: "",
            isCallModalOn: false,
          } as RemotePeerData;

          let pageNumAccordingToRoomType =
            meetingRoomReducer.screenCollabPeerArray.length > 0
              ? +environmentReducer.environments.REACT_APP_PAGE_SIZE_COLLAB
                .value
              : +environmentReducer.environments.REACT_APP_PAGE_SIZE.value;
          dispatch(
            addRemotePeer({
              remotePeer: peer,
              perPagePeerNum: pageNumAccordingToRoomType,
            })
          );
        }

        if (peer || selfUserId === message.peerUserId) {
          switch (message.status) {
            case "ADD_NEW_PEER":

              console.log("PEER ADDED", message)
              break;
            case "VIDEO_RESUME":
              console.log(selfUserId, message.peerUserId);
              if (selfUserId !== message.peerUserId) {
                let peerTemp = {
                  peerVideoPaused: false,
                };

                console.log(peerTemp, "video_resume");

                dispatch(
                  updateRemotePeer({
                    peerUserId: message.peerUserId,
                    remoteUserData: peerTemp,
                  })
                );
              } else {
                toast.success(`Your video has been resumed by the supervisor`);
                notifyMe("Your video has been resumed by the supervisor");
                if (isCameraNotificationsEnabled && camOnOffAlertIntervalRef.current) {
                  // store.dispatch(clearVideoPauseAlertInterval());
                  clearTimeout(camOnOffAlertIntervalRef.current);
                  camOnOffAlertIntervalRef.current = undefined;
                }else if(isCameraNotificationsEnabled && mediaReducer.isCamOffAlertFired) {
                  mediaSocketInstance.sendMessage("GET_ALERT_NOTIFICATION_FOR_CAMERA_ON_OFF", {
                    msg: "ON",
                    peerUserId: message.peerUserId,
                  });
                  store.dispatch(setIsCamOffAlertFired(false));
                }
                mediasoupInstance.videoProducer?.resume();

                if (faceDetectionFlag) {
                  faceDetection();
                }

                replaceVideoTimeout = setTimeout(() => {
                  streamInstance.replaceVideoStream(selectedTFLite);
                }, 200);
                // if (faceDetectionFlag) {
                //   faceDetection();
                // }
                // dispatch(videoTurnOrOff(false));
              }
              break;
            case "VIDEO_PRODUCING":
              await streamInstance.consumeVideoStream(peer);
              let peerTemp: any = {};

              if (peerTemp) {
                peerTemp.peerVideoProducing = true;
                peerTemp.peerVideoPaused = false;
                dispatch(
                  updateRemotePeer({
                    peerUserId: peer.peerUserId,
                    remoteUserData: peerTemp,
                  })
                );

                if (
                  constantMethod.checkHigherRole(currentUserRole) ||
                  roomReducer.viewMode === roomViewModeConst.collabMode
                ) {
                  // peer.peerVideoProducing = true;
                  // await this.consumeVideoStream(peer);
                  const pagePeers = getPagePeers();
                  console.log(pagePeers);
                  await streamInstance.resumeConsumersForPeer(pagePeers);
                } else if (
                  MULTIPLE_SUPERVISORS.status &&
                  peer.peerType !== role.AGENT &&
                  currentUserRole === role.AGENT
                ) {
                  await streamInstance.resumeConsumersForPeer([
                    peer.peerUserId,
                  ]);
                } else if (
                  peer.peerUserId === mediaReducer.presenter &&
                  currentUserRole === role.AGENT
                ) {
                  // peer.peerVideoProducing = true;
                  // await this.consumeVideoStream(peer);
                  await streamInstance.resumeConsumersForPeer([
                    peer.peerUserId,
                  ]);
                }
              }
              break;
            case "VIDEO_PAUSE":
              console.log("geetingin video");
              if (selfUserId !== message.peerUserId) {
                let peerTemp = {
                  peerVideoPaused: true,
                  numfaces: -1,
                  expression: "",
                  faceVerificationSuccess: false,
                  isNotSamePerson: false,
                  showAlertInParticipant: false
                };
                dispatch(clearVerifyFace());

                dispatch(
                  updateRemotePeer({
                    peerUserId: peer.peerUserId,
                    remoteUserData: peerTemp,
                  })
                );
              } else {
                toast.success(`Your video has been paused by the supervisor`);
                notifyMe("Your video has been paused by the supervisor");
                if (isCameraNotificationsEnabled && !constantMethod.checkHigherRole(currentUserRole)) {
                  console.log({kkkk6_OFF : camOnOffAlertIntervalRef.current});
                  // Set a new timeout and store it in Redux
                  camOnOffAlertIntervalRef.current = setTimeout(() => {
                    mediaSocketInstance.sendMessage("GET_ALERT_NOTIFICATION_FOR_CAMERA_ON_OFF", {
                      msg: "OFF",
                      peerUserId: message.peerUserId
                    });
                    toast.success("Your Camera is Off");
                    notifyMe("Your Camera is Off");
                    // store.dispatch(clearVideoPauseAlertInterval());
                    store.dispatch(setIsCamOffAlertFired(true));
                    clearTimeout(camOnOffAlertIntervalRef.current);
                    camOnOffAlertIntervalRef.current = undefined;
                  }, Number(cameraNotificationsTimerValue * 60 * 1000));
                }
                clearInterval(frstart.current);
                // socketInstance.sendMessage("FACE_DETECTED", {
                //   detections: [],
                //   time: Date.now(),
                //   videoPause: true,
                // });
                // mediasoupInstance.videoProducer?.pause();
                dispatch(setVideoOption("normal"));
                dispatch(setIsVirtualModalOpen(false));
                dispatch(setImageLink(""));
                dispatch(videoTurnOrOff(true));
              }
              break;
            case "AUDIO_PRODUCING":
              // Update Instructor array in redux IF its higher role
              if (constantMethod.checkHigherRole(peer.peerType)) {
                dispatch(insertInstructorId(peer.peerUserId));
              }
              // this.addPeerToArrayIfDoesNotExists(peer);

              if (message.audioNum === "File") {
                await streamInstance.consumeAudioFileStream(peer);

                let peerTemp = getRemotePeer(peer.peerUserId);
                if (peerTemp) {
                  let peerData = { peerAudioFileProducing: true };

                  dispatch(
                    updateRemotePeer({
                      peerUserId: peerTemp.peerUserId,
                      remoteUserData: peerData,
                    })
                  );
                }
              } else {
                await streamInstance.consumeAudioStream(peer);
                let peerTemp = getRemotePeer(peer.peerUserId);
                if (peerTemp) {
                  let peerData = { peerAudioProducing: true };
                  dispatch(
                    updateRemotePeer({
                      peerUserId: peerTemp.peerUserId,
                      remoteUserData: peerData,
                    })
                  );
                }
              }

              break;
            case "AUDIO_PAUSE":
              if (selfUserId !== message.peerUserId) {
                let peerTemp = {
                  peerAudioPaused: true,
                };

                dispatch(
                  updateRemotePeer({
                    peerUserId: peer.peerUserId,
                    remoteUserData: peerTemp,
                  })
                );

              } else {
                // this.notifyMe(`You have been muted by the supervisor`);
                toast.success(`You have been muted by the supervisor`);
                notifyMe("You have been muted by the supervisor");
                mediasoupInstance.audioProducer?.pause();
                dispatch(audioTurnOrOff(true));
                streamInstance.disconnectLocalHark()
              }

              break;
            case "AUDIO_RESUME":
              if (selfUserId !== message.peerUserId) {
                let peerTemp: any = {
                };

                if (peer) {
                  if (message.audioNum === "File") {
                    peerTemp.peerAudioFilePaused = false;
                  } else if (message.audioNum === "System") {
                    peerTemp.screenAudioPaused = false;
                  } else {
                    peerTemp.peerAudioPaused = false;
                  }
                  dispatch(
                    updateRemotePeer({
                      peerUserId: peer.peerUserId,
                      remoteUserData: peerTemp,
                    })
                  );
                }
              } else {
                if (observeReducer.openentObserverAudioId !== "") {
                  mediaSocketInstance.sendMessage("STOP_OBSERVE_AUDIO_AGENT", {
                    roomname: roomReducer.currentRoom,
                    userId: observeReducer.openentObserverAudioId,
                    from: "unmuteagent",
                  });
                }

                toast.success(`Your mic has been resumed by the supervisor`);
                notifyMe("Your mic has been resumed by the supervisor");
                mediasoupInstance.audioProducer?.resume();
                const track = mediasoupInstance.audioProducer?.track;
                if (track) {

                  streamInstance.connectLocalHark(track, message.peerUserId
                  );
                }
                dispatch(audioTurnOrOff(false));
              }
              break;
            case "SCREEN_PRODUCING":
              // this.addPeerToArrayIfDoesNotExists(peer);
              console.log("2232", { selectedTFLite });
              if (message.screenNum === "Two") {
                await streamInstance.consumeScreenTwoStream(peer);

                let peerTemp = getRemotePeer(peer.peerUserId);
                if (peerTemp) {
                  let peerData = { peerScreenTwoProducing: true };
                  dispatch(
                    updateRemotePeer({
                      peerUserId: peerTemp.peerUserId,
                      remoteUserData: peerData,
                    })
                  );
                }
              } else {
                await streamInstance.consumeScreenStream(peer);

                let peerTemp = getRemotePeer(peer.peerUserId);
                if (peerTemp) {
                  let peerData = { peerScreenProducing: true };
                  dispatch(
                    updateRemotePeer({
                      peerUserId: peerTemp.peerUserId,
                      remoteUserData: peerData,
                    })
                  );
                }
              }
              // }

              // CollabMode
              if (roomReducer.viewMode === roomViewModeConst.collabMode) {
                // add screen producer id
                let screenPeerIdEmptyOrNot =
                  meetingRoomReducer.screenCollabPeer.peerId;

                // add screen peer id into redux
                dispatch(
                  addCollabScreenPeer({
                    peerId: peer.peerUserId,
                    peerUsername: peer.peerUsername,
                  })
                );

                // if screen is not sharing by anyone
                if (screenPeerIdEmptyOrNot === "") {
                  // getting all peer session id for pause
                  const pagePausePeersUserIds = getPagePeers();
                  dispatch(
                    setStartAndEndIndex({
                      perPagePeerNum:
                        +environmentReducer.environments
                          .REACT_APP_PAGE_SIZE_COLLAB.value,
                    })
                  );
                  const pagePeersUserIds = getPagePeers();

                  const exactPausePeerUserIds = pagePausePeersUserIds.filter(
                    (pagePausePeersUserId) =>
                      !pagePeersUserIds.includes(pagePausePeersUserId)
                  );

                  if (exactPausePeerUserIds.length > 0) {
                    await streamInstance.pauseConsumersForPeer(
                      exactPausePeerUserIds
                    );
                  }

                  // resume for peer in one page according to collab page

                  await streamInstance.resumeConsumersForPeer(pagePeersUserIds);
                }
              }
              // admin , supervisor and manager
              else if (constantMethod.checkHigherRole(currentUserRole)) {
                const pagePeers = getPagePeers();
                console.log(pagePeers);
                await streamInstance.resumeConsumersForPeer(pagePeers);
              } else if (
                MULTIPLE_SUPERVISORS.status &&
                constantMethod.checkHigherRole(peer.peerType) &&
                currentUserRole === role.AGENT
              ) {
                await streamInstance.resumeConsumersForPeer([peer.peerUserId]);
              } else if (
                peer.peerUserId === mediaReducer.presenter &&
                currentUserRole === role.AGENT
              ) {
                await streamInstance.resumeConsumersForPeer([peer.peerUserId]);
              }
              break;
            case "SCREEN_STOP":
              if (selfUserId !== message.peerUserId) {
                let peerTemp: any = {};
                if (message.screenNum === "Two") {
                  peerTemp.peerscreenTwoStopped = true;
                  peerTemp.peerScreenTwoProducing = false;
                  if (streamInstance.peerRemoteStream) {
                    delete streamInstance.peerRemoteStream[peer.peerUserId]
                      .screenTwoStream;
                  }

                  dispatch(
                    updateRemotePeer({
                      peerUserId: peer.peerUserId,
                      remoteUserData: peerTemp,
                    })
                  );
                } else {
                  if (peer) {
                    if (roomReducer.viewMode === roomViewModeConst.collabMode) {
                      screenStopAndOfflinePeerForCollab(
                        peer.peerUserId,
                        meetingRoomConstant.stop
                      );
                    }
                    peerTemp.peerScreenStopped = true;
                    peerTemp.peerScreenProducing = false;
                    if (
                      streamInstance.peerRemoteStream &&
                      streamInstance.peerRemoteStream[peer.peerUserId]
                        .screenStream
                    ) {
                      delete streamInstance.peerRemoteStream[
                        peer.peerUserId
                      ].screenStream;
                    }
                    dispatch(
                      updateRemotePeer({
                        peerUserId: peer.peerUserId,
                        remoteUserData: peerTemp,
                      })
                    );
                  }
                  // else {
                  //   mediasoupInstance.screenProducer?.close();
                  //   mediasoupInstance.screenAudioProducer?.close();
                  //   peerTemp.audioSystemProducing = false;
                  //   dispatch(
                  //     updateRemotePeer({
                  //       peerUserId: message.peerUserId,
                  //       remoteUserData: peerTemp,
                  //     })
                  //   );
                  //   dispatch(setIsScreenAudioShared(false));
                  // }
                }
              }
              break;
            case "START_SCREEN_SHARE":
              if (message.screenNum === "Two") {
                streamInstance.startScreenTwo(
                  roomReducer.currentRoomId,
                  currentUserRole
                );
              } else {
                if (convertIntoBoolean(screenControl) || enableDesktopApp) {
                  // this.openElectronApp();
                  if (streamInstance.intervalForElectronScreenControl) {
                    clearInterval(
                      streamInstance.intervalForElectronScreenControl
                    );
                  }
                  if (currentUserRole === role.AGENT) {
                    socketInstanceDesktopApp.sendMessage(
                      "IS_ELECTRON_CONNECTED",
                      {
                        reconnect: false,
                      }
                    );
                  } else {
                    streamInstance.startScreenStream(
                      roomReducer.currentRoomId,
                      currentUserRole
                    );
                  }
                } else {
                  streamInstance.startScreenStream(
                    roomReducer.currentRoomId,
                    currentUserRole
                  );
                }
              }
              break;
            case "STOP_SCREEN_SHARE":
              if (message.screenNum === "Two") {
                if (mediasoupInstance.screenTwoProducer) {
                  mediasoupInstance.screenTwoProducer.close()
                  const track =
                    streamInstance.localSecondScreenStream?.getVideoTracks()[0];
                  track?.stop();
                  dispatch(screenTwoTurnOrOff(true));

                  streamInstance.localSecondScreenStream = undefined;
                  mediaSocketInstance.sendMessage("SCREEN_STOP", {
                    roomname: roomReducer.currentRoom,
                    screenNum: "Two",
                    peerUserId: selfUserId
                  });
                }
              } else {
                if (mediasoupInstance.screenProducer) {
                  mediasoupInstance.screenProducer.close();
                  const track =
                    streamInstance.localScreenStream?.getVideoTracks()[0];
                  track?.stop();
                  dispatch(screenTurnOrOff(true));

                  streamInstance.localScreenStream = undefined;

                  if (
                    (convertIntoBoolean(screenControl) || enableDesktopApp) &&
                    currentUserRole === role.AGENT
                  ) {
                    socketInstanceDesktopApp.sendMessage(
                      "APP_CLOSE_SCREEN_SHARE",
                      {}
                    );
                    if (!mediaReducer.localScreenStoppedTwo) {
                      streamInstance.unshareScreenTwo(message.perUserId);
                    }
                  }

                  if (streamInstance.localScreenFileStream) {
                    toast('Your video/audio has been stopped by another user. To share again, press "Cancel" and select a file.')
                    mediasoupInstance.screenProducer.close();
                    const track = streamInstance.localScreenFileStream?.getVideoTracks()[0];
                    track?.stop();
                    streamInstance.localScreenFileStream = undefined;
                    store.dispatch(setLocalScreenFileStopped(true));
                  }

                  mediaSocketInstance.sendMessage("SCREEN_STOP", {
                    roomname: roomReducer.currentRoom,
                    roomId: roomReducer.currentRoomId,
                    peerUserId: selfUserId
                  });

                  if (
                    mediasoupInstance.screenAudioProducer &&
                    streamInstance &&
                    streamInstance.localScreenStream
                  ) {
                    const screenAudioTrack =
                      //@ts-ignore
                      streamInstance.localScreenStream.getAudioTracks()[0];
                    screenAudioTrack?.stop();

                    mediaSocketInstance.sendMessage("SYSTEM_AUDIO_STOP", {
                      roomname: roomReducer.currentRoom,
                      roomId: roomReducer.currentRoomId,
                    });
                    dispatch(setIsScreenAudioShared(false));
                  }

                  if (streamInstance.localAudioFileStream) {
                    if (mediasoupInstance.audioFileProducer) {
                      mediasoupInstance.audioFileProducer.close();
                      const track = streamInstance.localAudioFileStream?.getAudioTracks()[0];
                      track?.stop();
                      mediaSocketInstance.sendMessage("AUDIO_STOP", {
                        roomname: roomReducer.currentRoom,
                      });
                      streamInstance.localAudioFileStream = undefined;
                      store.dispatch(setLocalAudioFilePaused(false))
                      store.dispatch(setAudioFile(false));
                    }
                  }
                }
              }
              break;

            case "SYSTEM_AUDIO_STOP":
              if (
                selfUserId !== message.peerUserId &&
                streamInstance &&
                streamInstance.peerRemoteStream
              ) {
                // this remote peer only getting stream peer it has store in streamInstance file
                const streamRemotePeer =
                  streamInstance.peerRemoteStream[message.peerUserId];
                // this remote peer for all remote peer data except stream of peer it is in media slice
                const peerToStop = getRemotePeer(message.peerUserId);

                if (peerToStop && streamRemotePeer) {
                  streamRemotePeer.screenAudioStream = undefined;
                  let peerStop = { screenAudioPaused: false };
                  dispatch(
                    updateRemotePeer({
                      peerUserId: peerToStop.peerUserId,
                      remoteUserData: peerStop,
                    })
                  );
                }
              }
              break;
            case "SCREEN_AUDIO_PRODUCING":
              if (message.audioNum === "System") {
                let peerTemp = {
                  audioSystemProducing: true,
                  systemAudioProducerRole: message.peerType,
                };
                dispatch(
                  updateRemotePeer({
                    peerUserId: peer.peerUserId,
                    remoteUserData: peerTemp,
                  })
                );
                await streamInstance.consumeSystemAudioStream(peer);
              }
              break;
          }
        }

        break;
    }
  };

  const removePeerIfAlreadyExist = async () => {
    if (errorMessages === "Peer Already exists in room") {
      await mediaSocketInstance.sendMessage("REMOVE_PEER", { remove: true });
    } else {
      await mediaSocketInstance.sendMessage(
        "REMOVE_PEER_FROM_ANOTHER_ROOM",
        {}
      );
    }
    navigate("/");
  };

  const processMessageDesktopApp = async (message: any) => {
    let mediaReducer = getMediaReducer();
    let localFileStreamReducer = getLocalFileStream();
    let reconnectForElectron = getreconnectionStatus();

    switch (message.type) {
      case "DESK_SOCKET_CONNECTED_FOR_FE":
        if (socketInstanceDesktopApp.socket) {
          socketInstanceDesktopApp.disconnectSocketId = socketInstanceDesktopApp.socket.id;
          console.log(socketInstanceDesktopApp.disconnectSocketId);

          if (reconnectForElectron.isReconnectionStatusScreenForElectron) {
            if (!constantMethod.checkHigherRole(user.role)) {
              socketInstanceDesktopApp.sendMessage("IS_ELECTRON_CONNECTED", {
                reconnect: reconnectForElectron.isReconnectionStatusScreenForElectron,
              });
            }
            store.dispatch(setIsReconnectionStatusScreenForElectron(false))
          }
        }
        break;
      case "IS_ELECTRON_CONNECTED":
        if (message.userId === selfUserId) {
          console.log(
            message.isElectronConnected,
            typeof message.isElectronConnected
          );
          if (message.isElectronConnected) {
            console.log("electron is connected");
            socketInstanceDesktopApp.sendMessage("APP_REQUEST_OFFER", {
              roomname: roomReducer.currentRoomId,
            });
          } else {
            console.log("electron is not connected");
            openElectronApp();
          }
        } else {
          console.log("else part for  is electron connected");
        }
        break;



      case "APP_ALREADY_ON_CONTROL_SCREEN":
        dispatch(setOpenentPeerId(""));
        dispatch(setIsScreenControl(false));
        toast.error(
          `can't take control screen, already has control access by someone`
        );
        break;

      case "RESTRICTED_APP_ALERT":
        console.log("restricted app alert", message);
        if (currentUserRole !== "Agent") {
          toast.error(`${message.msg} for ${message.name && !message.name.includes("undefined") ? message.name : message.username}`);
          dispatch(
            addEvent({
              userId: message.userId,
              event: EventType.RestrictedAppAlert,
              peerName: `${message.username}`,
              peerUsername: `${message.name && !message.name.includes("undefined") ? message.name : message.username}`,
              timestamp: Date.now(),
              startTime: Date.now(),
              message: `${message.msg}`,
            })
          );
        }
        break;
      case "SCREEN_SOCKET_CONNECTED_FOR_FE":
        console.log(selfUserId, message);
        if (message.userId === selfUserId) {
          if (socketInstanceDesktopApp.ifReconnect) {
            socketInstanceDesktopApp.ifReconnect = false;
            if (peerConnectionDesktopApp) {
              peerConnectionDesktopApp.close();
              peerConnectionDesktopApp = null;
              screenNumObj = {};
              // if (
              //   !mediaReducer.localScreenStopped &&
              //   !localFileStreamReducer.isVideoFile
              // ) {
              //   streamInstance.unshareScreen(selfUserId);
              // }
            }
          }

          console.log(mediasoupInstance.publishTransport, "geetingornot");
          if (mediasoupInstance.publishTransport) {
            // if(currentUserRole === "Agent") {
            socketInstanceDesktopApp.sendMessage("APP_REQUEST_OFFER", {
              roomId: roomReducer.currentRoomId,
            });
            // }
          } else {
            streamInstance.startScreenForElectron(roomReducer.currentRoomId);
          }
        }
        break;

      case "APP_GET_SCREEN_NUMBER":
        if (message.userId === selfUserId) {
          // screen_num_object = message.data;
          console.log("screen num obj", typeof message.data, message.data);
          // dispatch(setScreenNumObj(message.data))
          screenNumObj = message.data;
          socketInstanceDesktopApp.sendMessage("APP_SCREEN_NUM_STORED", {
            roomname: roomReducer.currentRoomId,
          });
        }
        break;

      case "APP_CANDIDATE_FROM_ELECTRON":
        if (message.userId === selfUserId) {
          try {
            console.log("[onCandidate] " + JSON.stringify(message));
            // TODO add iceCandidate to peerConnection
            peerConnectionDesktopApp.addIceCandidate(
              new RTCIceCandidate(message.data.jsep)
            );
          } catch (e) {
            console.log("[onCandidate] Exception " + e);
          }
        }
        break;

      case "APP_OFFER_FOR_SCREEN":
        if (message.userId === selfUserId) {
          try {
            let PEERCONNECTION_CONFIGURATION = {
              iceServers: [
                { urls: `${process.env.REACT_APP_STUN_SERVER_URL}` },
                {
                  urls: `${process.env.REACT_APP_TURN_SERVER_URL}`,
                  credential: `${process.env.REACT_APP_TURN_SERVER_CREDENTIAL}`,
                  username: `${process.env.REACT_APP_TURN_SERVER_USERNAME}`,
                },
              ],
            };

            peerConnectionDesktopApp = new RTCPeerConnection(
              PEERCONNECTION_CONFIGURATION
            );

            peerConnectionDesktopApp.addEventListener(
              "icecandidate",
              (e: any) => {
                console.log("ice candidate");
                onIceCandidate(e);
              }
            );
            peerConnectionDesktopApp.addEventListener("track", (e: any) => {
              console.log("ANUBH track event", e);
              if (e.track.kind === "audio") {
                streamInstance.systemAudioFromElectron = e;
                return;
              }
              if (e.streams.length > 0) {
                console.log(
                  "this is screen 1",
                  screenNumObj[e.streams[0].id],
                  screenNumObj
                );
                if (screenNumObj[e.streams[0].id] === "screen1") {
                  streamInstance.startScreenStream(
                    roomReducer.currentRoomId,
                    currentUserRole,
                    e
                  );
                  // TODO uncomment else condition and add condition for collab mode
                  // } else if (screenNumObj[e.streams[0].id] === "screen2" && this.props.viewMode !== roomViewModeConst.collabMode) {
                } else if (
                  screenNumObj[e.streams[0].id] === "screen2" &&
                  roomReducer.viewMode === roomViewModeConst.lectureMode
                ) {
                  streamInstance.startScreenTwo(
                    roomReducer.currentRoomId,
                    currentUserRole,
                    e
                  );
                }
              }
            });

            peerConnectionDesktopApp.addEventListener(
              "iceconnectionstatechange",
              (e: any) => {
                console.log("[onIceStateChange] Exception " + e);
              }
            );

            // We don't need to add any stream as we just need to receive it.
            peerConnectionDesktopApp.setRemoteDescription(
              new RTCSessionDescription(message.data.jsep)
            );
            // @ts-ignore
            const answer = await peerConnectionDesktopApp.createAnswer(
              OFFER_OPTIONS
            );
            await peerConnectionDesktopApp.setLocalDescription(answer);

            socketInstanceDesktopApp.sendMessage("APP_ANSWER", {
              jsep: answer,
            });
          } catch (e) {
            console.log("[onOffer] Exception " + e);
          }
        } else {
          // if (currentUserRole !== "Agent" && this.observerModeOn) {
          if (currentUserRole !== "Agent") {
            //Observe Agent audio automatically because electron is reconnected
            // Turn OFF the old observer
            document.getElementById("observerBtn")?.click();
            // Turn ON Observer Again
            setTimeout(() => {
              document.getElementById("observerBtn")?.click();
            }, 500);
          }
        }

        break;
      case "REMOVE_ELECTRON_PEER":
      case "APP_CLOSE_PEER_CONNECTION":
        if (message.username === username) {
          if (peerConnectionDesktopApp) {
            peerConnectionDesktopApp.close();
            peerConnectionDesktopApp = null;
            screenNumObj = {};
            if (
              !mediaReducer.localScreenStopped &&
              !localFileStreamReducer.isVideoFile
            ) {
              streamInstance.unshareScreen(selfUserId);
            }
          }
        }

        break;
    }
  };

  const onIceCandidate = async (event: any) => {
    try {
      if (!event.candidate) {
        // Looks like end of the candidates
        console.log("no ice candidate found.");
        return;
      }

      console.log(
        "[onIceCandidate] candidate " + JSON.stringify(event.candidate)
      );

      socketInstanceDesktopApp.sendMessage("APP_CANDIDATE_FROM_FE", {
        roomname: roomReducer.currentRoom,
        username: username,
        roomId: roomReducer.currentRoomId,
        userId: selfUserId,
        jsep: event.candidate,
      });
    } catch (e) {
      console.log("[onIceCandidate] Exception " + e);
    }
  };

  const fetchConnectionDetails = async () => {
    const connectionDetails: IConnectionDetails =
      await getConnectionDetailsForMedia(
        roomReducer.currentRoom,
        roomReducer.currentRoomId
      );

    let hostname = process.env.REACT_APP_MEDIA_WS_URL;

    if (!hostname) {
      if (window.location.origin.includes("localhost")) {
        hostname = "http://localhost:8080";
      } else {
        hostname = window.location.origin; // Default to the current origin
      }
    }

    console.log(
      hostname,
      connectionDetails.path,
      connectionDetails.payload,
      connectionDetails.iv,
      connectionDetails.digest,
      roomReducer.viewMode,
      `/socketPathName.media/${connectionDetails.path}`
    );

    if (!mediaSocketInstance.socket) {
      mediaSocketInstance.connectToSocket(
        hostname,
        connectionDetails.path,
        connectionDetails.payload,
        connectionDetails.iv,
        connectionDetails.digest,
        roomReducer.viewMode,
        `${socketPathName.media}/${connectionDetails.path}`
      );
    }
  };

  const screenStopAndOfflinePeerForCollab = async (
    peerUserId: string,
    type: string
  ) => {
    // checking peer who shared scrren and consuming

    let meetingRoomReducer = getMeetingRoomReducer();

    if (meetingRoomReducer.screenCollabPeer.peerId === peerUserId) {
      //  multipule screen share and screen stop of consuming peer so remove partiular peer and resume next array screen peer

      if (meetingRoomReducer.screenCollabPeerArray.length > 1) {
        dispatch(removeCollabScreenPeer({ peerId: peerUserId }));

        // dispatch(
        //   addCollabScreenPeer({
        //     ...getMeetingRoomReducer().screenCollabPeerArray[0],
        //   })
        // );

        if (type === meetingRoomConstant.offline) {
          removePeerFromReduxAndStreamInstance(peerUserId);
        } else {
          const currentPageUsersId = getPagePeers();

          await streamInstance.resumeConsumersForPeer(
            currentPageUsersId,
            getMeetingRoomReducer().screenCollabPeerArray[0].peerId
          );
        }
      } else {
        // only one peer shared screen and then peer stopped screen then it will continue for 9 peer in one page
        dispatch(removeCollabScreenPeer({ peerId: peerUserId }));
        const pauseCurrentPageUsersId = getPagePeers();

        // await this.pauseConsumersForPeer(pauseCurrentPageSessionIds);
        dispatch(
          setPage({
            perPagePeerNum:
              +environmentReducer.environments.REACT_APP_PAGE_SIZE.value,
          })
        );

        const currentPageUsersId = getPagePeers();

        const exactPausePeerUsersId = pauseCurrentPageUsersId.filter(
          (pauseCurrentPageUserId) =>
            !currentPageUsersId.includes(pauseCurrentPageUserId)
        );

        if (exactPausePeerUsersId.length > 0) {
          await streamInstance.pauseConsumersForPeer(exactPausePeerUsersId);
        }

        if (type === meetingRoomConstant.offline) {
          removePeerFromReduxAndStreamInstance(peerUserId);
        } else {
          await streamInstance.resumeConsumersForPeer(currentPageUsersId);
        }
      }
    } else {
      // if peer is in collab array list but not consume so it will remove form array
      dispatch(removeCollabScreenPeer({ peerId: peerUserId }));
    }
  };

  const fetchConnectionDetailsForDesktopApp = async () => {
    const connectionDetails: IConnectionDetails =
      await getConnectionDetailsForDesktopApp(
        roomReducer.currentRoom,
        roomReducer.currentRoomId
      );

    let hostname = process.env.REACT_APP_DESKTOP_WS_URL!;

    if (!hostname) {
      if (window.location.origin.includes("localhost")) {
        hostname = "http://localhost:8085";
      } else {
        hostname = window.location.origin; // Default to the current origin
      }
    }
    // let hostname = "https://crdev.ai";

    // if (hostname.includes("http://localhost")) {
    //   hostname = "http://localhost:8085";
    // }

    // if (hostname.includes("https://localhost")) {
    //   hostname = "https://localhost:8085";
    // }
    // else if (process.env.REACT_APP_DESKTOP_APP_URL) {
    //   hostname = process.env.REACT_APP_DESKTOP_APP_URL;
    // }
    // if (process.env.REACT_APP_SOCKET_SERVER_URL) {
    //   hostname = process.env.REACT_APP_SOCKET_SERVER_URL;
    // }

    console.log(
      hostname,
      connectionDetails.path,
      connectionDetails.payload,
      connectionDetails.iv,
      connectionDetails.digest,
      "",
      socketPathName.desktopApp
    );

    if (!socketInstanceDesktopApp.socket) {
      socketInstanceDesktopApp.connectToSocket(
        hostname,
        connectionDetails.path,
        connectionDetails.payload,
        connectionDetails.iv,
        connectionDetails.digest,
        "",
        `${socketPathName.desktopApp}`
        // false,
        // true
      );
    }
  };

  // start private call functionality
  // make or end private call
  const makePrivateCall = async (peerUserId: string) => {
    const peer = mediaReducer.remotePeers[peerUserId];
    let response: any;
    if (peer) {
      if (!peer.isPrivateCallOn) {
        let observeflag = false;

        if (observeReducer.openentObserverAudioId !== "") {
          observeflag = true;
          mediaSocketInstance.sendMessage("STOP_OBSERVE_AUDIO_AGENT", {
            roomname: roomReducer.currentRoom,
            userId: observeReducer.openentObserverAudioId,
            from: "privateagent",
          });
        } else if (observeReducer.openentObserveeAudioId !== "") {
          observeflag = true;
          mediaSocketInstance.sendMessage("STOP_OBSERVE_AUDIO_AGENT", {
            roomname: roomReducer.currentRoom,
            userId: observeReducer.openentObserveeAudioId,
            from: "privatesupervisor",
          });
        }

        dispatch(setCaller(true));
        response = await mediaSocketInstance.sendMessage("MAKE_PRIVATE_CALL", {
          roomname: roomReducer.currentRoom,
          roomId: roomReducer.currentRoomId,
          callAt: new Date(),
          userId: peerUserId,
          observeflag,
        });

        if (response?.type === "EMPTY_CALLBACK") {
          dispatch(
            setCallModal({
              ...privateCallReducer.callModalOption,
              peerFirstName: `${peer.peerName.split(" ")[0]}`,
              peerName: `${peer.peerName}`,
              callAcceptedButton: false,
              isCallModal: true,
              peerRemoteUserId: peerUserId,
            })
          );
        } else if (response.type === "FAILURE") {
          notifyMe(response.message);
          toast.error(response.message);
        }
      } else {
        response = await mediaSocketInstance.sendMessage("END_PRIVATE_CALL", {
          roomname: roomReducer.currentRoom,
          roomId: roomReducer.currentRoomId,
          userId: peerUserId,
        });

        if (response) {
          if (response?.type === "FAILURE") {
            notifyMe("Something error please try again");
            toast.error("Something error please try again");
          }
        }
      }
    }
  };

  const callRejected = async (peerUserId: string, typeAction: string) => {
    // const rp = this.getPeerFromRemotePeersArray(peerSessionId);
    let response: any;
    response = await mediaSocketInstance.sendMessage("CALL_REJECTED", {
      roomname: roomReducer.currentRoom,
      roomId: roomReducer.currentRoomId,
      userId: peerUserId,
      typeAction,
    });

    if (response) {
      if (response?.type === "EMPTY_CALLBACK") {
        dispatch(
          setCallModal({
            ...privateCallReducer.callModalOption,
            isCallModal: false,
            peerFirstName: "",
            callAcceptedButton: false,
            peerName: "",
            idForMakingCall: "",
            peerRemoteUserId: "",
          })
        );
      }
    }
  };

  const callAccepted = async () => {
    const rp =
      mediaReducer.remotePeers[
      privateCallReducer.callModalOption.idForMakingCall
      ];
    let response: any;
    if (rp) {
      if (!rp.isPrivateCallOn) {
        response = await mediaSocketInstance.sendMessage("START_PRIVATE_CALL", {
          roomname: roomReducer.currentRoom,
          roomId: roomReducer.currentRoomId,
          userId: privateCallReducer.callModalOption.idForMakingCall,
          callAt: new Date(),
        });

        if (response) {
          if (response?.type === "FAILURE") {
            toast.error(response.message);
          }

          if (response?.type === "EMPTY_CALLBACK") {
            dispatch(
              setCallModal({
                ...privateCallReducer.callModalOption,
                callAcceptedButton: false,
              })
            );
          }
        }
      }
    }
  };

  // end private call functionlity

  const detectApi = async (image: any) => {
    const features: string[] = [];
    if (headPoseDetect) {
      features.push("head_pose");
    }

    if (faceDetectionFlag) {
      features.push("face_detection");
    }

    if (faceVerification && faceVerificationEnabled && isUserVerified) {
      features.push("face_verification");
    }

    if (emotionDetectionAdminControl) {
      features.push("emotion_recognition");
    }

    if (cellphoneDetection) {
      features.push("cellphone_detection");
    }

    if (
      !faceDetectionFlag &&
      !faceVerification &&
      !emotionDetectionAdminControl &&
      !cellphoneDetection
    ) {
      features.length = 0;
    }
    const featuresString = JSON.stringify(features);

    try {
      // }`;

      const detectResponse = await detect(
        faceDetectionUrl,
        user.username,
        user.userId,
        user.custId,
        image,
        storeDetectionImages.value,
        featuresString,
        detectionToken
      );
      if (detectResponse.status === "success") {
        // console.log(
        //   "detection",
        //   detectResponse.n_person,
        //   detectResponse.n_phone
        // );
        // if (detectResponse?.errors) {

        //   if (detectResponse?.errors.face_verification) {
        //     toast.error(detectResponse.errors.face_verification);
        //   }
        //   if (detectResponse?.errors.face_detection) {
        //     toast.error(detectResponse.errors.face_detection);
        //   }
        //   if (detectResponse.errors.emotion_recognition) {
        //     toast.error(detectResponse.errors.emotion_recognition);
        //   }
        //   if (detectResponse.errors.cellphone_detection) {
        //     toast.error(detectResponse.errors.cellphone_detection);
        //   }
        // }
        let emotion;
        if (emotionDetectionAdminControl) {
          emotion = detectResponse.results.emotion_recognition;
        } else {
          emotion = "turned_off";
        }

        let headpose;
        if (headPoseDetect) {
          headpose = detectResponse.results.head_pose;
        } else {
          headpose = "turned_off";
        }

        if (headPoseDetect) {
          switch (headpose.toLowerCase()) {
            case "down":
            dispatch(incrementDownCount({ headPoseSeconds: (Number(headPoseSeconds) || 3), headPose: headpose }));
              break;
            case "up":
            case "left":
            case "right":
            case "straight":
            dispatch(incrementUpCount({ headPoseSeconds: (Number(headPoseSeconds) || 3), headPose: headpose }));
              break;
            case "":
              dispatch(resetCounts());
              break;
            default:
              break;
          }
        }
        mediaSocketInstance.sendMessage("FACE_DETECTED", {
          detections: {
            custId: user.custId,
            userId: selfUserId,
            face: detectResponse.results.face_detection,
            phone: detectResponse.results.cellphone_count,
            emotion,
            headpose,
            headDownCount: store.getState().headPoseCount.headDownCount,
            headUpCount: store.getState().headPoseCount.headUpCount,
          },
          emotionDetection: emotionDetectionAdminControl,
          time: Date.now(),
          headPoseDetect: headPoseDetect
        });

        if (
          detectResponse.results.face_detection >= 1 &&
          detectResponse.results.face_verification !== undefined &&
          !detectResponse.results.face_verification
        ) {
          console.log(
            "Coming Brooooooo",
            detectResponse.results.face_verification
          );
          dispatch(setWrongPerson(true));
          participantAlertCounterRef.current =
            participantAlertCounterRef.current + 1;
        } else {
          dispatch(setWrongPerson(false));
          participantAlertCounterRef.current = 0;
        }

        if (faceVerification) {
          mediaSocketInstance.sendMessage("VERIFY_FACE", {
            detections: {
              face: detectResponse.results.face_detection,
              faceVerification: detectResponse.results.face_verification,
              participantAlertCounter: participantAlertCounterRef.current,
            },
            time: Date.now(),
          });
        }

        if (detectResponse.results.cellphone_detection === 0) {
          setCellphoneDetected(false);
        }

        if (
          detectResponse.results.cellphone_count >= 1 &&
          detectResponse.results.cellphone_detection === true
        ) {
          let cellPhoneConfidence;
          if (detectResponse.results.max_cellphone_confidence) {
            cellPhoneConfidence =
              detectResponse.results.max_cellphone_confidence;
          }

          //&& !this.state.checkCellphoneDetected
          console.log("cell-phone is called");
          mediaSocketInstance.sendMessage("CELLPHONE_DETECTED", {
            class: "cell-phone",
            score: detectResponse.results.cellphone_detection,
            cellPhoneConfidence: cellPhoneConfidence,
          });
          setCellphoneDetected(true);

          // this.setState({
          //   checkCellphoneDetected: true
          // })
        }

        if (detectResponse.results.face_verification.toString().length > 0) {
          dispatch(setRecurrentCheck(detectResponse.results.face_verification));
        }

        if (
          detectResponse.results.face_verification.toString().length > 0 &&
          !detectResponse.results.face_verification
        ) {
          consecutiveFalseCountersRef.current =
            consecutiveFalseCountersRef.current + 1;
          console.log({
            "COming Here": detectResponse.results.face_verification,
          });
          if (consecutiveFalseCountersRef.current + 1 === 6) {
            dispatch(setVerifyFace(detectResponse.results.face_verification));
            toast.error(
              "Please make sure your face is clearly detected and you are in a well light room"
            );
            consecutiveFalseCountersRef.current = 0;
          }
        } else {
          consecutiveFalseCountersRef.current = 0;
          dispatch(setVerifyFace(true));
        }
      }
    } catch (error: any) {
      console.log("error while detection", error.message);
    }
  };
  const faceDetection = async () => {
    if (!constantMethod.checkHigherRole(user.role)) {
      frstart.current = setInterval(
        () => {
          let canvas: any = document.createElement("canvas");
          const input: any = document.getElementsByClassName(
            "face_detection_node"
          )[0];

          // console.log("I am Callded", input);
          if (input) {
            canvas.width = input.videoWidth;
            canvas.height = input.videoHeight;
            const offscreen = document.createElement("canvas");
            offscreen.width = canvas.width;
            offscreen.height = canvas.height;
            const ctx = offscreen.getContext("2d");
            ctx?.drawImage(input, 0, 0, canvas.width, canvas.height);
            console.log("inside roompagetsx 5");

            // offscreen.toBlob((blob: any) => {

            //   setFeedbackImageData({
            //     file: blob,
            //       agentUsername: message.agentUsername,
            //       parentRole: message.parentRole,
            //       parentUsername: message.parentUsername,
            //       room: message.room,
            //   })

            const imageData = offscreen.toDataURL("image/jpeg");
            detectApi(imageData);
          }

          // uploadFeedbackImg(blob, message.agentUsername, message.parentRole,message.parentUsername,message.room);

          // console.log("canvas image", imageData);

          // perform detection in worker
          // this.webWorker(input, data, canvas);
          // if (process.env.REACT_APP_MOBILE_DETECTION === "true") {
          //   this.objectDetectionWebWorker(input, objectDetectionData, canvas);
          // }
        },

        cellphoneFaceDetectSeconds * 1000
      );
    }
  };

  const removePeerInRedis = async () => {
    await mediaSocketInstance.sendMessage("REMOVE_PEER", { remove: true });
    goToDashboard();
  };

  const removePeerFromAnotherRoom = async () => {
    await mediaSocketInstance.sendMessage("REMOVE_PEER_FROM_ANOTHER_ROOM", {});
    goToDashboard();
  };

  const startDndTimer = () => {
    if (!isDndRunning) {
      dndWorkerRef.current = new Worker(new URL('../../workers/timerWorker.js', import.meta.url));
      dndWorkerRef.current.onmessage = (event: MessageEvent) => {
        setDndTime(event.data);
      };
      dndWorkerRef.current.postMessage('START');
      setIsDndRunning(true);
    }
  };

  const stopDndTimer = () => {
    if (isDndRunning) {
      dndWorkerRef.current?.postMessage('STOP');
      dndWorkerRef.current?.terminate();
      dndWorkerRef.current = null;
      setIsDndRunning(false);
    }
  };

  const dndModalControl = (value:boolean) => {
    setIsDndModalVisible(value);
  }
  
  useEffect(() => {
    return () => {
      // Cleanup on unmount
      dndWorkerRef.current?.postMessage('STOP');
      dndWorkerRef.current?.terminate();
    };
  }, []);


  return errorMessages !== "" ? (
    <div className=" w-screen h-screen flex flex-col justify-center items-center bg-gray-50">
      <div className="w-full max-w-md md:max-w-lg lg:max-w-xl h-auto p-6 bg-white rounded-2xl shadow-md flex flex-col justify-center items-center">
        <ExclamationCircleIcon className="h-20 w-20 text-red-600 bg-red-50 rounded-full" />
        <p className="text-red-600 text-lg mt-4 mb-2 text-center">
          {errorMessages === "Peer Already exists in room"
            ? `${errorMessages}::${roomReducer.currentRoom}`
            : errorMessages}
        </p>
        <p className="text-gray-500 mt-2 mb-2 text-center">
          {errorMessages === "Peer Already exists in room"
            ? "Are you sure you did not join the room in any other device or tabs?"
            : "If you want to logout from the other room, click here"}
        </p>
        {errorMessages === "Peer Already exists in room" && (
          <div className="text-gray-500 mb-4 flex flex-col items-center text-center">
            <p>If No, click on remove peer button.</p>
            <Button
              type="primary"
              className="mt-2"
              onClick={removePeerInRedis}
            >
              Remove Peer
            </Button>
          </div>
        )}
        {errorMessages === "You Already exists in Another Room, Can't Join Two Room at Same Time" && (
          <div className="flex justify-center items-center mt-2">
            <Button
              type="primary"
              className="ml-3"
              onClick={removePeerFromAnotherRoom}
            >
              Remove Peer
            </Button>
          </div>
        )}
      </div>
    </div>
  ) : (
    <>
      <div className=" w-full h-screen bg-gray-50 flex flex-col justify-between items-start p-[.7rem] pt-0 overflow-hidden gap-[1%]">
        <RoomNavControls
          active={active}
          setActive={setActive}
          isAlertActive={isAlertActive}
          setIsAlertActive={setIsAlertActive}
          isParticipantActive={isParticipantActive}
          setIsParticipantActive={setIsParticipantActive}
          isSidebarOpen={isSidebarOpen}
          setIsSidebarOpen={setIsSidebarOpen}
          dndTime={dndTime}
          dndModalControl={dndModalControl}
        />

        <div className="h-[90%] w-full flex justify-center items-center gap-4">
          <div
            className={`${isSidebarOpen ? "w-[78%]" : "w-full"} ${styles.height
              } rounded-2xl h-full flex justify-start items-start flex-col gap-1`}
          >
            {" "}
            {/* {
              mediaReducer.remotePeersId.map((remotePeerId: string) => {

                return !mediaReducer.remotePeers[remotePeerId].peerAudioPaused &&
                streamInstance.peerRemoteStream &&
                streamInstance.peerRemoteStream[remotePeerId].audioStream ? (
                <Audio
                  key={remotePeerId}
                  //@ts-ignore
                  srcObject={
                    streamInstance.peerRemoteStream[remotePeerId].audioStream
                  }
                />
              ) : null;
              })
            } */}
            {mediaReducer.remotePeersId.map((peerId: string) => {
              const peerRemoteStream =
                streamInstance.peerRemoteStream &&
                streamInstance.peerRemoteStream[peerId];
              const audioStream =
                peerRemoteStream && peerRemoteStream.audioStream;
              const screenAudioStream =
                peerRemoteStream && peerRemoteStream.screenAudioStream;
              const audioFileStream =
                peerRemoteStream && peerRemoteStream.audioFileStream;
              const audioComponents = [];

              if (audioStream) {
                audioComponents.push(
                  <Audio
                    key={`${peerId}-audio`}
                    srcObject={audioStream as MediaStream}
                  />
                );
              }


              if (audioFileStream) {
                audioComponents.push(
                  <Audio
                    key={`${peerId}-audio-file`}
                    srcObject={audioFileStream as MediaStream}
                  />
                );
              }


              if (
                mediaReducer.remotePeers[peerId]?.audioSystemProducing &&
                screenAudioStream
              ) {
                audioComponents.push(
                  <Audio
                    key={`${peerId}-screen-audio`}
                    srcObject={screenAudioStream as MediaStream}
                  />
                );
              }

              return audioComponents;
            })}
            <div className=" w-full h-[88%] rounded-xl bg-gray-300 shadow-sm p-1">
              {roomReducer.viewMode === roomViewModeConst.collabMode ? (
                <MeetingRoomScreen />
              ) : constantMethod.checkHigherRole(currentUserRole) ? (
                <InstructorScreen makePrivateCall={makePrivateCall} tflite={selectedTFLite} />
              ) : (
                <StudentScreen />
              )}
            </div>
            <div className=" w-full h-[12%] bg-white rounded-xl shadow-md p-1">
              <UserControls
                faceDetection={faceDetection}
                faceDetectionFlag={faceDetectionFlag}
                segmentationConfig={segmentationConfig}
                tflite={selectedTFLite}
                frstart={frstart}
                clearBeforeUnmount={clearBeforeUnmount}
                startDndTimer={startDndTimer}
                stopDndTimer={stopDndTimer}
                isDndModalVisible={isDndModalVisible}
                dndModalControl={dndModalControl}
                camOnOffAlertIntervalRef={camOnOffAlertIntervalRef}
              />
            </div>
          </div>
          <div
            className={` ${isSidebarOpen ? "w-[22%] block" : "w-0 hidden"} ${styles.height
              } relative`}
          >
            <RoomSidebar
              active={active}
              setActive={setActive}
              isAlertActive={isAlertActive}
              setIsAlertActive={setIsAlertActive}
              isParticipantActive={isParticipantActive}
              setIsParticipantActive={setIsParticipantActive}
              makePrivateCall={makePrivateCall}
              cellphoneDetected={cellphoneDetected}
              performRightShiftByOne={performRightShiftByOne}
            />
          </div>
        </div>
        {/* Private Call  */}
        {privateCallReducer.callModalOption.isCallModal ? (
          <PrivateCallModal
            makePrivateCall={makePrivateCall}
            callRejected={callRejected}
            callAccepted={callAccepted}
          />
        ) : null}
      </div>
    </>
  );
}
