import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UserDetailsProps } from "../../interfaces/slicesInterface/userDetails";
import {
  getUserDetails,
  getBackgroundActivate,
  saveBackgroundActivate,
} from "../../services/userDetails";
import toast from "react-hot-toast";
import { RootState } from "../../app/store";

const initialState: UserDetailsProps = {
  loading: false,
  username: "",
  firstName: "",
  lastName: "",
  role: "",
  permRole:"",
  bussunit: "",
  passwordUpdatedOn: "",
  complexPassword: false,
  enableDesktopApp: false,
  faceVerification: {
    isFaceVerificationEnabled: false,
    image: "",
    isRegistered: false,
    isRejected: false,
    isVerified: false,
  },
  isVerified: false,
  isRegistered: false,
  custCode: "",
  custId: null,
  mfa: false,
  userId: "",
  error: "",
  cameraBackground: { backgroundType: "normal", url: "" },
  apiFulfilled: false
};

const userDetailsSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setCameraBackground: (state, action: PayloadAction<any>) => {
      state.cameraBackground = action.payload;
    },
    setRole: (state, action: PayloadAction<any>) => {
      state.role = action.payload;
    },
    setPermRole:(state,action:PayloadAction<any>)=>{
state.permRole = action.payload;  
    },
    setFulfilled: (state, action: PayloadAction<any>) => {
      state.apiFulfilled = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserDetails.pending, (state) => {
        state.loading = true;
        state.apiFulfilled = false;
      })
      .addCase(
        getUserDetails.fulfilled,
        (state, action: PayloadAction<any>) => {
          if (action.payload.success) {
            console.log({ user: action.payload.user });
            state.loading = false;
            state.username = action.payload.user.username;
            state.firstName = action.payload.user.firstName;
            state.lastName = action.payload.user.lastName;
            state.role = action.payload.user.role;
            state.permRole = action.payload.user.role; // adding a new field to set the permamnet role for the reference
            state.bussunit = action.payload.user.bussunit;
            state.passwordUpdatedOn = action.payload.user.passwordUpdatedOn;
            state.complexPassword = action.payload.user.complexPassword;
            state.enableDesktopApp = action.payload.user.enableDesktopApp;
            state.faceVerification = action.payload.user.faceVerification;
            state.isVerified = action.payload.user.isVerified;
            state.isRegistered = action.payload.user.isRegistered;
            state.custCode = action.payload.user.custCode;
            state.custId = action.payload.user.custId;
            state.mfa = action.payload.user.mfa;
            state.userId = action.payload.user._id;

            if(action.payload.user && action.payload.user.token) {
              localStorage.setItem("token", action.payload.user.token);
            }
            state.apiFulfilled = true;
          } else {
            state.loading = false;
            state.apiFulfilled = false;
            toast.error("Cannot fetch user details");
          }
        }
      )
      .addCase(getUserDetails.rejected, (state, action) => {
        state.error = action.error.message;
        state.loading = false;
        state.apiFulfilled = false;
        toast.error("Error in getting User Details");
      });
    builder.addCase(getBackgroundActivate.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      getBackgroundActivate.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.loading = false;
        if (action.payload.success) {
          state.cameraBackground = action.payload.userBackground;
        } else {
          toast.error("Cannot get user background");
        }
      }
    );
    builder.addCase(getBackgroundActivate.rejected, (state, action) => {
      state.error = action.error.message;
      state.loading = false;
      toast.error("Error in getting Background");
    });
    builder.addCase(saveBackgroundActivate.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(saveBackgroundActivate.fulfilled, (state, action) => {
      state.loading = false;
      if (action.payload.success) {
        state.error = "";
      } else {
        toast.error("Cannot Save Background Activate");
      }
    });
    builder.addCase(saveBackgroundActivate.rejected, (state, action) => {
      state.error = action.error.message;
      state.loading = false;
      toast.error("Error in Saving Background");
    });
  },
});

export const userDetailsSelector = (state: RootState) => state.user;
export const { setCameraBackground, setRole, setFulfilled } = userDetailsSlice.actions;
export default userDetailsSlice.reducer;
